import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { ReactComponent as Check } from './check.svg';
import { Divider } from 'antd';
import { CheckboxValueType, CheckboxGroupProps } from 'antd/lib/checkbox/Group';

const Container = styled.div`
  ${tw`rounded-sm p-2 inline-flex items-center justify-between bg-white`}
  height: 36px;
`;

const Element = styled.button`
  ${tw`inline-flex items-center`}
  outline: 0 !important;
`;

const CircleBox = styled.div`
  ${tw`rounded-full border border-primary w-4 h-4 ml-2 flex items-center justify-center relative`}
`;

const StyledCheck = styled(Check)`
  ${tw`text-primary w-4 h-4 absolute`}
`;

export interface CheckListProps extends CheckboxGroupProps {
  className?: string;
  options?: { value: CheckboxValueType; label: CheckboxValueType }[];
}

export const CheckList: React.FC<CheckListProps> = ({
  className,
  options = [],
  defaultValue = [],
  value,
  onChange,
}) => {
  const [list, setList] = useState<Array<CheckboxValueType>>(defaultValue);

  useEffect(() => {
    onChange && onChange(list);
  }, [list]); // eslint-disable-line

  useEffect(() => {
    value && setList(value);
  }, [value]); // eslint-disable-line

  return (
    <Container className={className} data-testid="check-list">
      {options.map((option, idx: number) => {
        const checked = list.includes(option.value);

        return (
          <>
            <Element
              onClick={() =>
                setList(list =>
                  !checked
                    ? [...list, option.value]
                    : list.filter(l => l !== option.value),
                )
              }
            >
              {option.label}
              <CircleBox>{checked && <StyledCheck />}</CircleBox>
            </Element>
            {idx < options.length - 1 && (
              <Divider type="vertical" className="h-full text-grey" />
            )}
          </>
        );
      })}
    </Container>
  );
};

export default CheckList;
