import React, { useState, useEffect, useRef } from 'react';
import { PageHeader, Button, Layout, PopupConfirm } from '@aha/ui';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from 'styled-components';
import { validate } from './validator';
import useSelectProviders from 'containers/SelectServiceProviders/useSelectServiceProviders';
import ServiceForm from 'components/ServiceForm';
import { ServiceCreateServiceRequest } from 'types/schema';
import useSelectDashboardState from 'containers/Dashboard/useSelectDashboardState';
import { coreAPI } from 'utils/request';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';
import { navigate } from '@reach/router';
import ROUTES from 'constants/routes';

const Container = styled.form`
  margin-bottom: 1rem;
  .ant-row.ant-form-item {
    width: 100%;
  }
`;

export type CreateServiceRequest = Partial<ServiceCreateServiceRequest> & {
  addEn?: string;
  addZh?: string;
  addVi?: string;
};

export default function CreateExtraServicePage() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { providers } = useSelectProviders();
  const { collapsed, cityID } = useSelectDashboardState();
  const firstLoading = useRef<boolean>(false);

  useEffect(() => {
    if (firstLoading.current) {
      navigate('external-services');
    }
    firstLoading.current = true;
  }, [cityID]);

  const initialValue: CreateServiceRequest = {
    images: [],
    price: 0,
    providerID: providers?.[0]?.id as string,
    shortDescriptionEn: [''],
    shortDescriptionVi: [''],
    shortDescriptionZh: [''],
  };

  const [initialValues, setInitialValues] = useState<CreateServiceRequest>(
    initialValue,
  );

  useEffect(() => {
    if (providers) {
      setInitialValues(prev => ({
        ...prev,
        ...initialValue,
      }));
    }
  }, [providers]); // eslint-disable-line

  async function onSubmit(body: CreateServiceRequest) {
    try {
      //remove extra params that we're using to input items
      delete body.addEn;
      delete body.addVi;
      delete body.addZh;
      const props = body as ServiceCreateServiceRequest;

      setIsSubmitting(true);
      const newBody: ServiceCreateServiceRequest = {
        ...props,
        type: 'external',
        defaultImage:
          Array.isArray(props.images) && props.images.length >= 1
            ? props.images[0]
            : '',
      };

      await coreAPI.post('v1/operation/services', newBody);
      showSuccessNotification(
        'The external service has been created successfully ',
      );
      setInitialValues(initialValue);
    } catch (e) {
      showErrorNotification('Cannot create external service', e);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form<CreateServiceRequest>
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      validate={validate}
      mutators={{
        ...arrayMutators,
        clearField: (args, state, { changeValue }) =>
          changeValue(state, args[0], () => ''),
      }}
      initialValues={initialValues}
      render={({ handleSubmit, form: { reset } }) => (
        <Container id="service-form" onSubmit={handleSubmit}>
          <PageHeader title="Create External Service" />
          <ServiceForm isSubmitting={isSubmitting} />
          <Layout.Footer
            collapsed={collapsed}
            extraLeft={
              <PopupConfirm
                placement="topRight"
                title="Are you going to reset this service?"
                onCancel={() => {
                  reset(initialValues);
                  setInitialValues(initialValue);
                }}
                okText="No"
                cancelText="Yes"
              >
                <Button
                  type="line"
                  color="secondary"
                  size="large"
                  className="bg-grey-light border-none hover:bg-secondary"
                >
                  Reset
                </Button>
              </PopupConfirm>
            }
            extraRight={
              <div className="inline-block">
                <Button
                  className="w-32"
                  size="large"
                  type="line"
                  color="primary"
                  loading={isSubmitting}
                  onClick={() => navigate(ROUTES.DASHBOARD)}
                >
                  Cancel
                </Button>
                <Button
                  className="w-32 ml-4"
                  size="large"
                  color="primary"
                  loading={isSubmitting}
                  onClick={() => {
                    document
                      ?.getElementById('service-form')
                      ?.dispatchEvent(
                        new Event('submit', { cancelable: true }),
                      );
                  }}
                >
                  Create
                </Button>
              </div>
            }
          ></Layout.Footer>
        </Container>
      )}
    ></Form>
  );
}
