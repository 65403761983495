import { ApplicationRootState } from 'types/app';

type T = { [k in keyof ApplicationRootState]: (state: object) => object };

const reducers: (keyof ApplicationRootState)[] = ['auth', 'dashboard'];

export const mockReducers = reducers.reduce(
  (a: T, c) => ({ ...a, [c]: (state = {}) => state }),
  {} as T,
);
