import React from 'react';
import useSelectServiceUnits from './useSelectServiceUnits';
import { FormSelect } from '@aha/ui';
import { FormSelectOptions } from '@aha/ui/src/components/FormSelect';

export interface FormSelectUnitsProps {
  name: string;
  label?: string;
  className?: string;
  placeholder?: string;
}

const FormSelectUnits = (props: FormSelectUnitsProps) => {
  const { units } = useSelectServiceUnits();
  return (
    <FormSelect
      className="w-full"
      {...props}
      options={
        (units || []).map(({ code, id }) => ({
          name: code,
          value: id,
        })) as FormSelectOptions
      }
    />
  );
};

export default FormSelectUnits;
