import { CreateServiceRequest } from '.';

export function validate(values: CreateServiceRequest) {
  const {
    categoryID,
    descriptionEn,
    descriptionVi,
    descriptionZh,
    nameEn,
    nameVi,
    nameZh,
    providerID,
    unitID,
    shortDescriptionEn,
    shortDescriptionVi,
    shortDescriptionZh,
    price,
    images,
  } = values;

  const errors: { [k: string]: any } = {
    shortDescriptionEn: [],
    shortDescriptionVi: [],
    shortDescriptionZh: [],
  };

  if (!categoryID) {
    errors.categoryID = 'Required';
  }

  if (!descriptionEn) {
    errors.descriptionEn = 'Required';
  }
  if (!descriptionVi) {
    errors.descriptionVi = 'Required';
  }
  if (!descriptionZh) {
    errors.descriptionZh = 'Required';
  }
  if (!nameEn) {
    errors.nameEn = 'Required';
  }
  if (!nameVi) {
    errors.nameVi = 'Required';
  }
  if (!nameZh) {
    errors.nameZh = 'Required';
  }
  if (!providerID) {
    errors.providerID = 'Required';
  }
  if (!unitID) {
    errors.unitID = 'Required';
  }

  if (price === null) {
    errors.price = 'Required';
  } else if (typeof price !== 'number') {
    errors.price = 'Invalid';
  }

  if (!(Array.isArray(images) && images.length >= 1)) {
    errors.images = 'Required';
  }

  shortDescriptionEn?.forEach((des, idx) => {
    if (!des) {
      errors.shortDescriptionEn[idx] = 'Required';
    }

    if (!shortDescriptionVi?.[idx]) {
      errors.shortDescriptionVi[idx] = 'Required';
    }

    if (!shortDescriptionZh?.[idx]) {
      errors.shortDescriptionZh[idx] = 'Required';
    }
  });

  if (!errors.shortDescriptionEn.length) {
    delete errors.shortDescriptionEn;
  }

  if (!errors.shortDescriptionVi.length) {
    delete errors.shortDescriptionVi;
  }

  if (!errors.shortDescriptionZh.length) {
    delete errors.shortDescriptionZh;
  }

  return errors;
}
