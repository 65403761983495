import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

export interface ChartTooltipProps {
  value: string;
  name: string;
  style?: React.CSSProperties;
  nameClassName?: string;
  valueClassName?: string;
}

const Container = styled.div`
  position: absolute;
  width: 0;
  height: 0;
`;

const Tip = styled.div`
  ${tw`border border-primary text-center absolute bg-white`}
  border-radius: 3px;
  white-space: nowrap;
  min-width: 150px;
  padding: 8px 20px;
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
`;

const Name = styled.span`
  ${tw`font-semibold text-lg text-primary uppercase`}
`;

const Value = styled.div`
  ${tw`uppercase text-2xs text-grey-darker`}
`;

const Arrow = styled.span`
  position: absolute;
  bottom: -7px;
  left: 50%;
  width: 12px;
  height: 12px;
  transform: translateX(-6px) rotate(135deg);
  ${tw`border-t border-primary border-r bg-white`}
`;

export default function ChartTooltip({
  name,
  value,
  style,
  nameClassName,
  valueClassName,
}: ChartTooltipProps) {
  return (
    <Container style={style}>
      <Tip>
        <Name className={nameClassName}>{value}</Name>
        <Value className={valueClassName}>{name}</Value>
        <Arrow />
      </Tip>
    </Container>
  );
}
