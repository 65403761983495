import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectIsLogin,
  makeSelectCurrentUser,
  makeSelectCurrentUserIsLoading,
} from 'containers/Auth/selectors';
import { ApplicationRootState } from 'types/app';
import { useInjectSaga } from 'utils/injectSaga';
import authSaga from 'containers/Auth/saga';
import { ViewmodelUser } from 'types/schema';
import { fetchUser } from 'containers/Auth/actions';
import { fetchLocations } from 'containers/SelectLocation/actions';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    isLogin: boolean;
    user: ViewmodelUser | null;
    userIsLoading: boolean;
  }
>({
  isLogin: makeSelectIsLogin(),
  user: makeSelectCurrentUser(),
  userIsLoading: makeSelectCurrentUserIsLoading(),
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchCurrentUser: () => dispatch(fetchUser()),
  doFetchLocations: () => dispatch(fetchLocations()),
});

export default function useAppIsReady() {
  useInjectSaga({ key: 'auth', saga: authSaga });

  const dispatch = useDispatch();
  const { fetchCurrentUser } = mapDispatchToProps(dispatch);
  const { isLogin, user, userIsLoading } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!user) {
      fetchCurrentUser();
    }
  }, []); // eslint-disable-line

  return {
    isLogin,
    failedToFetch: !user,
    isLoading: userIsLoading,
    user,
  };
}
