import React, { ReactElement } from 'react';
import { FormSpy } from 'react-final-form';
import classnames from 'classnames';
import { LanguageType } from './MainContent';

interface FormValidTabProps {
  label: string;
  type: LanguageType;
}

export default function FormValidTab({
  label,
  type,
}: FormValidTabProps): ReactElement {
  return (
    <FormSpy
      subscription={{
        errors: true,
        submitFailed: true,
      }}
      render={({ submitFailed, errors }) => {
        const shortDescription = errors[`shortDescription${type}`];
        const description = errors[`description${type}`];
        const name = errors[`name${type}`];

        return (
          <span
            className={classnames({
              'text-red':
                (shortDescription || description || name) && submitFailed,
            })}
          >
            {label}
          </span>
        );
      }}
    />
  );
}
