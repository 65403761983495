import { takeLatest, call, all, put, takeEvery } from 'redux-saga/effects';
import NProgress from 'nprogress';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { ExternalServiceActions } from './types';
import { coreAPI } from 'utils/request';
import {
  fetchExternalServiceDetailFailure,
  fetchExternalServiceDetailSuccess,
  fetchHotelAssignSuccess,
} from './actions';
import { showErrorNotification } from '@aha/utils';
import { ServiceService, HotelHotelListResponse } from 'types/schema';

export function* doFetchExternalServiceDetail(
  action: ExtractAction<
    ExternalServiceActions,
    ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL
  >,
) {
  NProgress.start();
  const { payload: serviceID } = action;
  try {
    const data: ServiceService = yield call(
      coreAPI.get,
      `/v1/operation/services/${serviceID}`,
    );

    if (data) {
      yield put(fetchExternalServiceDetailSuccess(data));
    }
  } catch (err) {
    yield put(fetchExternalServiceDetailFailure(err));
  }
  NProgress.done();
}

export function* watchFetchExternalServiceDetail() {
  yield takeLatest(
    ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL,
    doFetchExternalServiceDetail,
  );
}

export function* doFetchListHotelAssign(
  action: ExtractAction<
    ExternalServiceActions,
    ActionTypes.FETCH_HOTEL_LIST_ASSIGN
  >,
) {
  yield call(NProgress.start);
  try {
    const cityID = 'ba352576-afe2-4f16-8e68-164e8e7c495a';
    const resp: HotelHotelListResponse = yield call(
      coreAPI.get,
      `/v1/operation/hotels?cityID=${cityID}`,
    );
    yield put(fetchHotelAssignSuccess(resp));
  } catch (err) {
    showErrorNotification('Fetch hotel list fail', err);
  }
  yield call(NProgress.done);
}

export function* watchFetchHotelAssign() {
  yield takeEvery(ActionTypes.FETCH_HOTEL_LIST_ASSIGN, doFetchListHotelAssign);
}

export default function* watchExternalServiceAll() {
  yield all([watchFetchExternalServiceDetail(), watchFetchHotelAssign()]);
}
