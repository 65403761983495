import React, { useState, useMemo } from 'react';
import { Icon } from '@aha/ui';
import { Dropdown } from 'antd';
import { ViewmodelDistrict } from './actions';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import classnames from 'classnames';
import styled from 'styled-components';

export interface DropdownFilter {
  districtData: ViewmodelDistrict[];
  onFilter: (values: string) => void;
}

const ButtonFilter = styled.button`
  &:focus {
    outline: none;
  }
`;

const allDistrict = 'All district';

export const DropdownFilter = ({ districtData, onFilter }: DropdownFilter) => {
  const [selectedKeys, setSelectedKeys] = useState<string>('');
  const [selectedLabel, setSelectedLabel] = useState<string>(allDistrict);
  const options: CheckboxOptionType[] = useMemo(() => {
    let initalDistrictData = [
      {
        label: allDistrict,
        value: '',
      },
    ];
    if (districtData) {
      const optionsDistrict = districtData.map(district => {
        return {
          label: district.name || '',
          value: district.id || '',
        };
      });
      return initalDistrictData.concat(optionsDistrict);
    }

    return initalDistrictData;
  }, [districtData]);
  const menu = (
    <React.Fragment>
      <div
        className="bg-white shadow-md overflow-y-auto mt-1 flex flex-col py-2"
        style={{ height: 300, width: 200 }}
      >
        {options.map((items, index) => {
          return (
            <ButtonFilter
              className={classnames(
                'pl-6 pr-2 py-1 h-8 flex justify-between hover:bg-grey-lighter items-center',
                {
                  'bg-grey-lighter': selectedKeys === items.value,
                },
              )}
              key={index}
              onClick={() => {
                setSelectedKeys(String(items.value));
                setSelectedLabel(String(items.label));
                onFilter(String(items.value));
              }}
            >
              <span>{items.label}</span>
              {selectedKeys === items.value && <Icon type="check" />}
            </ButtonFilter>
          );
        })}
      </div>
    </React.Fragment>
  );

  const isFilter = selectedLabel !== allDistrict;

  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
      <ButtonFilter
        className={classnames('flex items-center hover:text-primary', {
          'text-primary': isFilter,
        })}
      >
        {selectedLabel}
        <Icon
          type={isFilter ? 'filter-solid' : 'filter'}
          className={classnames('ml-2', {
            'text-primary': isFilter,
          })}
        />
      </ButtonFilter>
    </Dropdown>
  );
};

export default DropdownFilter;
