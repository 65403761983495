import * as React from 'react';
import { Input as AntInputQuantity } from 'antd';
import { InputProps } from 'antd/lib/input';
import { Button, ButtonProps } from '../Button';
import classnames from 'classnames';
import styled from 'styled-components';

export interface InputQuantityProps extends Omit<InputProps, 'onChange'> {
  className?: string;
  step?: number;
  children?: any;
  htmltype?: string;
  value?: number | string;
  onClickSuffix?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrefix?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChange?: (value: number) => void;
}

const StyledInput = styled(AntInputQuantity)<InputProps>`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .ant-input {
    text-align: center !important;
  }
  .ant-input-suffix {
    right: 6px;
    &:hover {
      color: var(--primary);
    }
  }
  .ant-input-prefix {
    left: 6px;
    &:hover {
      color: var(--primary);
    }
  }
  .ant-input-disabled:hover {
    border: 1px solid var(--grey-dark) !important;
  }
`;

const StyledButton = styled(Button)<ButtonProps>`
  .btn__icon {
    line-height: 0 !important;
  }
`;

export default function InputQuantity({
  children,
  className = '',
  step = 1,
  value,
  htmltype,
  onClickSuffix,
  onClickPrefix,
  onChange,
  ...props
}: InputQuantityProps) {
  function onMinus(e: React.MouseEvent<HTMLButtonElement>) {
    if (typeof onClickPrefix === 'function') {
      onClickPrefix(e);
    } else if (typeof onChange === 'function' && !isNaN(+value)) {
      onChange(+value - step);
    }
  }

  function onPlus(e: React.MouseEvent<HTMLButtonElement>) {
    if (typeof onClickSuffix === 'function') {
      onClickSuffix(e);
    } else if (typeof onChange === 'function' && !isNaN(+value)) {
      onChange(+value + step);
    }
  }

  return (
    <StyledInput
      data-testid="input-quality"
      suffix={
        <StyledButton
          type="icon"
          icon="plus"
          htmlType="button"
          className="text-sm"
          onClick={onPlus}
          data-testid="input-quality__plus"
        />
      }
      prefix={
        <StyledButton
          type="icon"
          icon="minus"
          htmlType="button"
          className="text-sm"
          onClick={onMinus}
          data-testid="input-quality__minus"
        />
      }
      className={classnames({
        [className]: className,
      })}
      type="number"
      onChange={e => {
        if (onChange && e.target.value) {
          onChange(+e.target.value);
        }
      }}
      value={value}
      {...props}
    />
  );
}
