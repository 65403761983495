import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ModelCoin } from 'types/schema';
import { InjectPromise } from 'types/actions';

export const fetchLoyaltySettings = () =>
  action(ActionTypes.FETCH_LOYALTY_SETTINGS);

export const fetchLoyaltySettingsSuccess = (loyalty: ModelCoin) =>
  action(ActionTypes.FETCH_LOYALTY_SETTINGS_SUCCESS, loyalty);

export const fetchLoyaltySettingsFailure = (error: Error) =>
  action(ActionTypes.FETCH_LOYALTY_SETTINGS_FAILURE, error);

export const editLoyaltySettings = (params: InjectPromise<any, ModelCoin>) =>
  action(ActionTypes.EDIT_LOYALTY_SETTINGS, params);

export const editLoyaltySettingsSuccess = (loyalty: ModelCoin) =>
  action(ActionTypes.EDIT_LOYALTY_SETTINGS_SUCCESS, loyalty);
