import { ProvidersState, ProvidersAction } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: ProvidersState = {
  loading: false,
  error: null,
  providers: null,
};

const providersReducer = (
  state: ProvidersState = initialState,
  action: ProvidersAction,
): ProvidersState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_PROVIDERS:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_PROVIDERS_SUCCESS:
        draft.loading = false;
        draft.providers = action.payload;
        break;
      case ActionTypes.FETCH_PROVIDERS_FAILURE:
        draft.loading = false;
        break;
    }
  });

export default providersReducer;
