import { takeLatest, takeEvery, call, all, put } from 'redux-saga/effects';
import NProgress from 'nprogress';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { coreAPI } from 'utils/request';
import {
  fetchLoyaltySettingsFailure,
  fetchLoyaltySettingsSuccess,
} from './actions';
import { showErrorNotification } from '@aha/utils';
import { LoyaltySettingsActions } from './types';

export function* doFetchLoyaltySettings() {
  NProgress.start();
  try {
    const data = yield call(coreAPI.get, '/v1/operation/memberships/configs');
    yield put(fetchLoyaltySettingsSuccess(data));
  } catch (err) {
    yield put(fetchLoyaltySettingsFailure(err));
    showErrorNotification('Fetch loyalty settings fail', err);
  }
  NProgress.done();
}

export function* watchFetchLoyaltySettings() {
  yield takeLatest(ActionTypes.FETCH_LOYALTY_SETTINGS, doFetchLoyaltySettings);
}

export function* doEditLoyaltySettings(
  action: ExtractAction<
    LoyaltySettingsActions,
    ActionTypes.EDIT_LOYALTY_SETTINGS
  >,
) {
  NProgress.start();
  const {
    payload: { data: body, resolve, reject },
  } = action;

  try {
    const data = yield call(
      coreAPI.post,
      '/v1/operation/memberships/configs',
      body,
    );
    yield put(fetchLoyaltySettingsSuccess(data));
    resolve();
  } catch (err) {
    showErrorNotification('Edit loyalty settings fail', err);
    reject();
  }
  NProgress.done();
}

export function* watchEditLoyaltySettings() {
  yield takeEvery(ActionTypes.EDIT_LOYALTY_SETTINGS, doEditLoyaltySettings);
}

export default function* watchExternalServiceAll() {
  yield all([watchFetchLoyaltySettings(), watchEditLoyaltySettings()]);
}
