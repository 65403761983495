import React from 'react';
import { Layout } from 'antd';
import { Skeleton } from '@uxui/skeleton-loader';
import HeaderSkeleton from './HeaderLoader';
import tw from 'tailwind.macro';
import styled from 'styled-components';
const { Sider } = Layout;
const { Content } = Layout;

const StyledHeader = styled.header`
  ${tw`flex items-center justify-between bg-white px-4`}
  height: 56px;
`;

export default function DashboardLoader() {
  return (
    <Layout className="min-h-screen">
      <Sider
        width="220"
        trigger={null}
        className="p-4 hidden lg:block"
        theme="light"
      >
        <Skeleton width="100%" height="16px" className="mb-1" />
        <Skeleton width="80%" height="16px" className="mb-1" />
        <Skeleton width="70%" height="16px" className="mb-1" />
      </Sider>
      <Layout>
        <StyledHeader>
          <div className="flex w-full items-center justify-between">
            <Skeleton width="24px" height="24px" />
            <div className="flex items-center">
              <Skeleton width="26px" height="26px" radius="999px" />
              <div className="pl-2 pr-6 flex flex-col">
                <Skeleton width="40px" height="10px" />
              </div>
              <Skeleton width="20px" height="20px" radius="999px" />
            </div>
          </div>
        </StyledHeader>
        <div style={{ minHeight: 'calc(100vh - 56px)' }}>
          <Content className="px-4 pb-6">
            <HeaderSkeleton />
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
              <Skeleton width="180px" height="16px" />
              <div className="my-2" />
              <Skeleton width="220px" height="16px" />
              <div className="my-2" />
              <Skeleton width="200px" height="16px" />
              <div className="my-2" />
              <Skeleton width="120px" height="16px" />
            </div>
          </Content>
        </div>
      </Layout>
    </Layout>
  );
}
