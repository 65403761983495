import React from 'react';
import { Tabs, FormInput, Button, FormTextArea } from '@aha/ui';
import { FieldArray } from 'react-final-form-arrays';
import { FormSpy } from 'react-final-form';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import ContentItem from './ContentItem';
import { getIn } from 'final-form';
import FormValidTab from './FormValidTab';
const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-tab {
    &:not(.ant-tabs-tab-active) > span {
      ${tw`text-secondary`}
    }

    &:first-child {
      padding-left: 0 !important;
    }
  }
`;

export const Block = styled.div`
  ${tw`w-6 h-6 ml-2`}
  min-width: 24px;
`;

export type LanguageType = 'En' | 'Vi' | 'Zh';
export const LANGUAGE_TYPES: LanguageType[] = ['En', 'Vi', 'Zh'];

interface ContentProps {
  type: LanguageType;
}

function Content({ type }: ContentProps) {
  return (
    <div className="border rounded-sm px-6 py-3">
      <div className="flex flex-col mb-1 mt-2">
        <div className="inline-flex items-center w-full md:w-1/2">
          <FormInput
            name={`name${type}`}
            label="Service title"
            placeholder="Ex: Motor Scooter"
            className="w-1/2 md:w-full"
          />
          <Block className="hidden md:block" />
        </div>
        <FieldArray name={`shortDescription${type}`}>
          {({ fields }) => (
            <>
              {fields.map((name, idx) => (
                <ContentItem
                  key={name}
                  name={name}
                  label={`Item ${idx + 1}`}
                  index={idx}
                />
              ))}
              <div className="inline-flex w-1/2 relative mt-1">
                <FormInput
                  name={`add${type}`}
                  label="New item"
                  className="w-full"
                  placeholder="Ex: Round-Trip, Identity Card, Passport..."
                />
                <FormSpy
                  subscription={{ values: true }}
                  render={({
                    values,
                    form: {
                      mutators: { clearField, push },
                    },
                  }) => {
                    const addValue = getIn(values, `add${type}`);
                    const filterLangs = LANGUAGE_TYPES.filter(t => t !== type);

                    return (
                      <Button
                        size="small"
                        type="icon"
                        icon="plus-cirle"
                        className="ml-2 mt-6 sm:mt-7 -mr-8 md:mr-0 text-primary absolute md:relative pin-r"
                        onClick={() => {
                          push(`shortDescription${type}`, addValue);
                          filterLangs.forEach(t =>
                            push(`shortDescription${t}`, ''),
                          );
                          clearField(`add${type}`);
                        }}
                      />
                    );
                  }}
                ></FormSpy>
              </div>
            </>
          )}
        </FieldArray>
      </div>
      <FormTextArea
        name={`description${type}`}
        label="Description"
        autosize={{ minRows: 6 }}
      />
    </div>
  );
}

interface MainContentProps {
  className?: string;
}

const MainContent = (props: MainContentProps) => {
  return (
    <div {...props}>
      <div className="capitalize text-secondary text-base font-semibold">
        Main Content
      </div>
      <i className="text-xs text-grey-darker">
        <span className="text-red mr-1 font-bold">*</span>
        Required information
      </i>
      <StyledTabs
        tabBarStyle={{ padding: 0, marginBottom: 0, borderBottom: 0 }}
        tabBarGutter={4}
      >
        <TabPane tab={<FormValidTab label="English" type="En" />} key="en">
          <Content type="En" />
        </TabPane>
        <TabPane tab={<FormValidTab label="Vietnamese" type="Vi" />} key="vi">
          <Content type="Vi" />
        </TabPane>
        <TabPane tab={<FormValidTab label="Chinese" type="Zh" />} key="zh">
          <Content type="Zh" />
        </TabPane>
      </StyledTabs>
    </div>
  );
};

export default MainContent;
