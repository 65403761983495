import { call, put, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import { fetchProvidersSuccess, fetchProvidersFailure } from './actions';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { ProvidersAction } from './types';
import { ProviderProviderListResponse } from 'types/schema';

export function* fetchProviders(
  action: ExtractAction<ProvidersAction, ActionTypes.FETCH_PROVIDERS>,
) {
  const { payload: providerType } = action;

  try {
    let url = 'v1/operation/providers';
    if (providerType) {
      url = `${url}?providerType=${providerType}`;
    }

    const { data }: ProviderProviderListResponse = yield call(coreAPI.get, url);
    yield put(fetchProvidersSuccess(data || []));
  } catch (err) {
    yield put(fetchProvidersFailure(err));
  }
}

export default function* watchFetchProviders() {
  yield takeLatest(ActionTypes.FETCH_PROVIDERS, fetchProviders);
}
