import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectDevelopState = (state: ApplicationRootState) => {
  return state.dashboard || initialState;
};

const makeSelectDashboardCollapsed = () =>
  createSelector(
    selectDevelopState,
    dashboardState => dashboardState.collapsed,
  );

const makeSelectCurrentCityId = () =>
  createSelector(selectDevelopState, dashboardState => dashboardState.cityID);

export { makeSelectDashboardCollapsed, makeSelectCurrentCityId };
