import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ServicecategoryCategory, HotelCategory } from 'types/schema';

export const fetchCategories = () => action(ActionTypes.FETCH_CATEGORIES);

export const fetchCategoriesSuccess = (categories: ServicecategoryCategory[]) =>
  action(ActionTypes.FETCH_CATEGORIES_SUCCESS, categories);

export const fetchCategoriesFailure = (error: Error) =>
  action(ActionTypes.FETCH_CATEGORIES_FAILURE, error);

export const fetchHotelCategories = (hotelID: string) =>
  action(ActionTypes.FETCH_HOTEL_CATEGORIES, hotelID);

export const fetchHotelCategoriesSuccess = (categories: HotelCategory[]) =>
  action(ActionTypes.FETCH_HOTEL_CATEGORIES_SUCCESS, categories);

export const fetchHotelCategoriesFailure = (error: Error) =>
  action(ActionTypes.FETCH_HOTEL_CATEGORIES_FAILURE, error);
