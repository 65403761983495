import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Icon } from '@aha/ui';

const Container = styled.div`
  ${tw`rounded-sm flex-none p-2 border border-grey-lighter`}
  width: 213px;
  height: 107px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export interface MulitpleImageItemProps {
  imgUrl?: string;
  onRemove?: () => void;
}

const MultipleImageItem = ({
  imgUrl = '',
  onRemove,
}: MulitpleImageItemProps) => {
  return (
    <Container style={{ backgroundImage: `url(${imgUrl})` }}>
      {typeof onRemove === 'function' && (
        <div className="close-button-container flex justify-end">
          <button
            onClick={e => {
              e.preventDefault();
              onRemove();
            }}
          >
            <Icon
              type="times-circle-solid"
              className="text-grey-darkest hover:text-black-light text-sm"
            />
          </button>
        </div>
      )}
    </Container>
  );
};

export default MultipleImageItem;
