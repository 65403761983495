import React from 'react';
import MultipleUploadItem from './MultipleUploadItem';
import MultipleImageItem from '../MultipleImageItem';

export interface MultipleImageUploaderProps {
  imageUrls: string[];
  onChange?: (files: any) => void;
  onRemove?: (imgUrl: string) => void;
  uploading?: boolean;
  limit?: number;
  required?: boolean;
  addPhotoText?: string;
  uploadingText?: string;
}

const MultipleImageUploader = ({
  imageUrls,
  onChange,
  onRemove,
  uploading = false,
  required = false,
  limit = 5,
  addPhotoText = 'Add Photo',
  uploadingText = 'Photo is being uploaded',
}: MultipleImageUploaderProps) => {
  return (
    <div className="flex items-center flex-wrap">
      {(imageUrls || []).map((item, index) => (
        <div key={index} className="m-2">
          <MultipleImageItem
            onRemove={() => {
              onRemove && onRemove(item);
            }}
            imgUrl={item}
          />
        </div>
      ))}
      {imageUrls && imageUrls.length < limit && (
        <div className="m-2 border">
          <MultipleUploadItem
            required={required}
            onChange={onChange}
            uploading={uploading}
            addPhotoText={addPhotoText}
            uploadingText={uploadingText}
          />
        </div>
      )}
    </div>
  );
};

export default MultipleImageUploader;
