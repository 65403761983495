import React from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import classnames from 'classnames';

export type LegendColor =
  | 'blue'
  | 'orange'
  | 'green-darkest'
  | 'green'
  | 'green-lighter'
  | 'red'
  | 'blue-lightest'
  | 'purple'
  | 'cyan'
  | 'cyan-lightest'
  | 'cyan-darkest'
  | 'primary'
  | 'secondary'
  | 'yellow';

export type LegendType = 'square' | 'line' | 'caret-down' | 'caret-up';

export interface LegendProps {
  text: string;
  className?: string;
  type?: LegendType;
  colored?: LegendColor;
}

const Square = styled.span<{ colored: string }>`
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 2px;
  margin-right: 7px;
  background-color: ${props => `var(--${props.colored})`};
`;

const Line = styled.span<{ colored: string }>`
  display: block;
  width: 9px;
  height: 2px;
  border-radius: 2px;
  margin-right: 7px;
  background-color: ${props => `var(--${props.colored})`};
`;

const CaretUp = styled.span<{ colored: string }>`
  display: block;
  width: 10px;
  border-right: solid 5px transparent;
  border-left: solid 5px transparent;
  border-bottom: solid 9px ${props => `var(--${props.colored})`};
  margin-right: 7px;
`;

const CaretDown = styled.span<{ colored: string }>`
  display: block;
  width: 10px;
  border-right: solid 5px transparent;
  border-left: solid 5px transparent;
  border-top: solid 9px ${props => `var(--${props.colored})`};
  margin-right: 7px;
`;

const Container = styled.div`
  ${tw`inline-flex items-center text-2xs leading-none`}
  height: 16px;
`;

export type LegendShape = 'square' | 'line' | 'caret-up' | 'caret-down';

const components: { [k in LegendShape]: React.ElementType } = {
  square: Square,
  line: Line,
  'caret-up': CaretUp,
  'caret-down': CaretDown,
};

const Legend = ({
  text,
  colored = 'green',
  className,
  type = 'square',
}: LegendProps) => {
  const RenderLegend = components[type];
  return (
    <Container
      className={classnames({
        [className]: className,
      })}
      data-testid="legend"
    >
      <RenderLegend colored={colored} />
      <span className="text-grey-darker">{text}</span>
    </Container>
  );
};

export default Legend;
