import React from 'react';
import { createAntField } from '../CreateAntField';
import { Field } from 'react-final-form';
import DateRangePicker, { DateRangePickerProps } from '../DateRangePicker';

const FormDateRangePickerWrapper = createAntField<DateRangePickerProps, any>(
  // @ts-ignore
  DateRangePicker,
);

export interface FormDateRangePickerProps extends DateRangePickerProps {
  name: string;
  label?: React.ReactNode;
  allowClear?: boolean;
  dateFormat?: string;
  className?: string;
}

const FormDateRangePicker = ({
  format = 'DD MMM YYYY',
  allowClear = false,
  ...props
}: FormDateRangePickerProps) => {
  return (
    <Field
      {...props}
      dateFormat={format}
      allowClear={allowClear}
      component={FormDateRangePickerWrapper}
    />
  );
};

export default FormDateRangePicker;
