import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Skeleton } from '@uxui/skeleton-loader';

const Header = styled.header`
  ${tw`flex items-center justify-between`};
  height: 60px;
`;

const HeaderLoader = () => {
  return (
    <Header>
      <div>
        <Skeleton
          width="200px"
          height="19px"
          baseColor="#ddd"
          highlightColor="#eee"
        />
        <Skeleton
          className="ml-4"
          width="100px"
          height="19px"
          baseColor="#ddd"
          highlightColor="#eee"
        />
      </div>
      <div>
        <Skeleton
          className="ml-4"
          width="100px"
          height="14px"
          baseColor="#ddd"
          highlightColor="#eee"
        />
      </div>
    </Header>
  );
};

export default HeaderLoader;
