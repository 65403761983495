import { ApplicationRootState } from 'types/app';

const initialStateHolder = (function() {
  let instance: Partial<ApplicationRootState>;

  function createInstance() {
    const object = {};
    return object;
  }

  return {
    getInstance: function() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    setState: function<T>(key: keyof ApplicationRootState, state: T): T {
      const instance = this.getInstance();

      if (!instance[key]) {
        instance[key] = state;
        return instance[key];
      } else {
        throw new Error(`The key "${key}" was used!!!`);
      }
    },
  };
})();

export default initialStateHolder;
