import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectUnitsState = (state: ApplicationRootState) => {
  return state.units || initialState;
};

const makeSelectUnitsLoading = () =>
  createSelector(selectUnitsState, unitsState => unitsState.loading);

const makeSelectUnitsError = () =>
  createSelector(selectUnitsState, unitsState => unitsState.error);

const makeSelectUnits = () =>
  createSelector(selectUnitsState, unitsState => unitsState.units);

export { makeSelectUnits, makeSelectUnitsError, makeSelectUnitsLoading };
