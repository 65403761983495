import React from 'react';
import { Field } from 'react-final-form';
import { ProviderProvider } from 'types/schema';
import useSelectProviders from 'containers/SelectServiceProviders/useSelectServiceProviders';

export interface FormProviderInfoProps {
  name: string;
}

const FormProviderInfo = ({ name }: FormProviderInfoProps) => {
  const { providers } = useSelectProviders();
  if (!providers) {
    return null;
  }

  return (
    <Field<string>
      name={name}
      render={({ input: { value } }) => {
        const provider: ProviderProvider | undefined = providers.find(
          p => p.id === value,
        );

        if (!provider) {
          return null;
        }

        const { city, district, street, ward, phone } = provider;
        const providerOptions = [
          { value: phone, label: 'Phone' },
          { value: street, label: 'Street' },
          { value: ward, label: 'Ward' },
          { value: district, label: 'District' },
          { value: city, label: 'City' },
        ];

        return (
          <div className="leading-normal mt-4">
            <div className="text-secondary text-base font-medium mb-3">
              Contact of Provider
            </div>
            {providerOptions.map(({ label, value }, idx) => (
              <div key={idx} className="mt-1">
                <span className="font-medium mr-1">{`${label}`}:</span>
                {value}
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};

export default FormProviderInfo;
