import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Steps from '../Steps';
import SubHeading from '../SubHeading';

export interface WizardProps {
  current: string;
  isLoading?: boolean;
  children: React.ReactNode;
}

const Container = styled.div`
  ${tw`flex`}
  min-height: calc(100vh - 130px);
`;

const Sidebar = styled.div`
  ${tw`flex-none bg-grey-lightest p-5`};
  width: 186px;
`;

const Body = styled.div`
  ${tw`relative bg-white`}
  width : calc(100% - 186px);
`;

const Footer = styled.div`
  ${tw`absolute pin-x pin-b px-4 border-t flex items-center justify-between bg-white`};
  height: 56px;
  z-index: 1;
`;

const ComponentContainer = styled.div`
  ${tw`absolute pin px-8 py-16`};
  overflow-x: hidden;
  overflow-y: auto;
`;

const Header = styled.header`
  ${tw`px-8 flex items-center justify-between bg-white absolute pin-x pin-t`};
  height: 56px;
  z-index: 9;
`;

const FormBody = styled.div`
  ${tw`absolute overflow-y-scroll pin px-8`};
  padding-top: 76px;
  padding-bottom: 76px;
`;

const Wizard = ({ children, current }: WizardProps) => {
  // @ts-ignore
  const steps = React.Children.toArray(children).map(({ props }) => ({
    ...props,
  }));

  const newChildren = React.Children.map(children, child => {
    return React.cloneElement(child as React.ReactElement<any>, {
      current,
    });
  });

  const items = React.useMemo(
    () =>
      steps
        .map(({ name, id }) => ({ id, name }))
        .filter((_, index) => index !== steps.length - 1),
    [],
  );
  const completed = steps[steps.length - 1].id === current;

  return (
    <Container>
      <Sidebar>
        <Steps completed={completed} current={current} items={items} />
      </Sidebar>
      <Body>
        {newChildren.find(
          // @ts-ignore
          ({ props }) => props.id === current,
        )}
      </Body>
    </Container>
  );
};

interface WizardStepProps {
  id: string;
  name: string;
  Component: React.FunctionComponent<any> | any;
}

Wizard.Step = React.memo(({ Component, id }: WizardStepProps) => {
  return (
    <ComponentContainer>
      <Component />
    </ComponentContainer>
  );
});

interface WizardHeaderProps {
  title: string;
  extra?: React.ReactNode;
}

Wizard.Header = ({ extra, title }: WizardHeaderProps) => (
  <Header>
    <SubHeading>{title}</SubHeading>
    {extra && <div>{extra}</div>}
  </Header>
);

interface WizardBodyProps {
  children?: React.ReactNode;
}

Wizard.Body = ({ children }: WizardBodyProps) => (
  <FormBody>{children}</FormBody>
);

interface WizardFooterProps {
  leftExtra?: React.ReactNode;
  rightExtra?: React.ReactNode;
}

Wizard.Footer = ({ leftExtra, rightExtra }: WizardFooterProps) => (
  <Footer>
    <div className="pr-5 bg-white">{leftExtra}</div> <div>{rightExtra}</div>
  </Footer>
);

export default Wizard;
