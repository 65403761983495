enum ActionTypes {
  SET_EXTERNAL_SERVICE_DETAIL = 'externalService/SET_EXTERNAL_SERVICE_DETAIL',

  FETCH_EXTERNAL_SERVICE_DETAIL = 'externalService/FETCH_EXTERNAL_SERVICE_DETAIL',
  FETCH_EXTERNAL_SERVICE_DETAIL_SUCCESS = 'externalService/FETCH_EXTERNAL_SERVICE_DETAIL_SUCCESS',
  FETCH_EXTERNAL_SERVICE_DETAIL_FAILURE = 'externalService/FETCH_EXTERNAL_SERVICE_DETAIL_FAILURE',
  FETCH_HOTEL_LIST_ASSIGN = 'externalService/FETCH_HOTEL_LIST_ASSIGN',
  FETCH_HOTEL_LIST_ASSIGN_SUCCESS = 'externalService/FETCH_HOTEL_LIST_ASSIGN_SUCCESS',
  FETCH_HOTEL_LIST_ASSIGN_FAILURE = 'externalService/FETCH_HOTEL_LIST_ASSIGN_FAILURE',
}
export default ActionTypes;
