import { takeLatest, call, all, put } from 'redux-saga/effects';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { DashboardActions } from './types';
import { setSelectedCity } from 'utils/request';
import { switchCurrentCitySuccess } from './actions';

export function* doSwitchCurrentCity(
  action: ExtractAction<DashboardActions, ActionTypes.SWITCH_CURRENT_CITY>,
) {
  const { payload } = action;
  yield call(setSelectedCity, payload);
  yield put(switchCurrentCitySuccess(payload));
}

export function* watchSwitchCurrentCity() {
  yield takeLatest(ActionTypes.SWITCH_CURRENT_CITY, doSwitchCurrentCity);
}

export default function* watchAuthAll() {
  yield all([watchSwitchCurrentCity()]);
}
