import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { MultipleImagesUploader } from '@aha/ui';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';

export interface FormMultipleImagesUploaderProps {
  name: string;
}

const FormMultipleImagesUploader = (props: FormMultipleImagesUploaderProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  return (
    <Field<string[]>
      {...props}
      render={({ input: { value, onChange }, meta: { submitFailed } }) => (
        <MultipleImagesUploader
          required={value.length < 1 && submitFailed}
          imageUrls={value || []}
          uploading={isUploading}
          onRemove={imageUrl => onChange(value.filter(v => v !== imageUrl))}
          onChange={async file => {
            try {
              setIsUploading(true);
              const data = new FormData();
              data.append('file', file);
              const { url } = await coreAPI.post('/v1/contents', data);
              url && onChange([...value, url]);
            } catch (err) {
              showErrorNotification('Cannot upload file', err);
            } finally {
              setIsUploading(false);
            }
          }}
        />
      )}
    />
  );
};

export default FormMultipleImagesUploader;
