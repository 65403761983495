import React from 'react';
import { numberFormatterBack } from '@aha/utils';
import { FormInputNumber } from '@aha/ui';
import FormSelectCategories from 'containers/SelectServiceCategories/FormSelectCategories';
import MainContent, { Block } from './components/MainContent';
import FormSelectUnits from 'containers/SelectServiceUnits/FormSelectUnits';
import FormMultipleImagesUploader from 'components/FormMultipleImagesUploader';
import FormProviderInfo from './components/FormProviderInfo';
import FormSelectProviders from 'containers/SelectServiceProviders/FormSelectProviders';
import classnames from 'classnames';

export type ServiceFormProps = {
  isSubmitting?: boolean;
  isInternal?: boolean;
};

export const ServiceForm: React.FC<ServiceFormProps> = ({
  isSubmitting,
  isInternal = false,
}) => {
  return (
    <div
      className={classnames('bg-white px-6 pb-5 pt-2', {
        'opacity-75 pointer-events-none': isSubmitting,
        'pb-6': !isInternal,
        'pb-5': isInternal,
      })}
    >
      <section className="inline-flex items-center w-1/2 mb-4">
        <FormSelectCategories name="categoryID" label="Category" />
        <Block className="hidden md:block" />
      </section>
      <MainContent className="mb-3" />
      <section className="inline-flex mb-5 w-full md:w-1/2">
        <div className="pr-2 w-1/2">
          <FormInputNumber
            name="price"
            label="Price"
            className="w-full"
            placeholder="Ex: đ200,000"
            formatter={(value: number | string | undefined = 0) => {
              const formatNumber = value
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

              return formatNumber;
            }}
            parser={(value?: string) =>
              numberFormatterBack((value || '0') as string)
            }
          />
        </div>
        <div className="pl-2 w-1/2">
          <FormSelectUnits
            name="unitID"
            label="Unit"
            placeholder="Select Unit"
          />
        </div>
        <Block className="hidden md:block" />
      </section>
      <section className={classnames({ 'mb-3': !isInternal })}>
        <div className="capitalize text-secondary text-base font-semibold mb-3">
          Service Photos
        </div>
        <div className="mb-2">
          Photos must be at least 640px by 320px. Maximum 5 photos.
        </div>
        <div className="text-red mt-1 mb-2">
          * The first photo is selected to the primary image.
        </div>
        <FormMultipleImagesUploader name="images" />
      </section>
      {!isInternal && (
        <section>
          <div className="inline-flex items-center w-full md:w-1/2">
            <FormSelectProviders name="providerID" label="Provider" />
            <div className="w-1/2 pl-2" />
            <Block className="hidden md:block" />
          </div>
          <FormProviderInfo name="providerID" />
        </section>
      )}
    </div>
  );
};

export default ServiceForm;
