import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectCategoriesState = (state: ApplicationRootState) => {
  return state.categories || initialState;
};

const makeSelectCategoriesLoading = () =>
  createSelector(
    selectCategoriesState,
    categoriesState => categoriesState.loading,
  );

const makeSelectCategoriesError = () =>
  createSelector(
    selectCategoriesState,
    categoriesState => categoriesState.error,
  );

const makeSelectCategories = () =>
  createSelector(
    selectCategoriesState,
    categoriesState => categoriesState.categories,
  );

const makeSelectHotelCategoriesLoading = () =>
  createSelector(
    selectCategoriesState,
    categoriesState => categoriesState.hotelCategoriesLoading,
  );

const makeSelectHotelCategories = () =>
  createSelector(
    selectCategoriesState,
    categoriesState => categoriesState.hotelCategories,
  );

export {
  makeSelectCategories,
  makeSelectCategoriesError,
  makeSelectCategoriesLoading,
  makeSelectHotelCategoriesLoading,
  makeSelectHotelCategories,
};
