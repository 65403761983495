import * as React from 'react';
import Icon from '../Icon';
import styled from 'styled-components';
import classnames from 'classnames';

export interface TrendProps {
  down?: boolean;
  percent: number;
  className?: string;
}

const StyledIcon = styled(Icon)<{ down: number }>`
  line-height: 1em;
  transform: ${props =>
    props.down ? 'none' : 'rotate(180deg) translateY(1px)'};
`;

const Trend = ({ down = false, percent, className = '' }: TrendProps) => {
  return (
    <span
      className={classnames(
        'inline-flex items-center font-medium text-2xs leading-none',
        {
          [className]: className,
          'text-red': down,
          'text-green': !down,
        },
      )}
      data-testid="trend"
    >
      <StyledIcon down={down ? 1 : 0} type="sort-down" className="mr-1" />{' '}
      {percent}%
    </span>
  );
};

export default Trend;
