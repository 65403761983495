import React, { useState, useEffect, useRef } from 'react';
import { PageHeader, Button, Layout, SkeletonTable } from '@aha/ui';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from 'styled-components';
import { validate } from 'containers/CreateExtraService/validator';
import { ServiceUpdateServiceRequest, ServiceService } from 'types/schema';
import useSelectDashboardState from 'containers/Dashboard/useSelectDashboardState';
import ServiceForm from 'components/ServiceForm';
import {
  showErrorNotification,
  showSuccessNotification,
  formatRoute,
} from '@aha/utils';
import { coreAPI } from 'utils/request';
import { RouteComponentProps, navigate } from '@reach/router';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectExternalServiceDetail,
  makeSelectExternalServiceDetailLoading,
} from 'containers/ExternalServicesPage/selectors';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types/app';
import { fetchExternalServiceDetail } from 'containers/ExternalServicesPage/actions';
import saga from 'containers/ExternalServicesPage/saga';
import reducer from 'containers/ExternalServicesPage/reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import ROUTES from 'constants/routes';

const Container = styled.form`
  margin-bottom: 1rem;
  .ant-row.ant-form-item {
    width: 100%;
  }
`;

export type UpdateServiceRequest = Partial<ServiceUpdateServiceRequest> & {
  addEn?: string;
  addZh?: string;
  addVi?: string;
};

interface EditInternalServicePageProps {
  hotelId: string;
  serviceId: string;
}

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    serviceDetail: ServiceService | null;
    isLoading: boolean;
  }
>({
  serviceDetail: makeSelectExternalServiceDetail(),
  isLoading: makeSelectExternalServiceDetailLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchServiceDetail: (serviceID: string) =>
    dispatch(fetchExternalServiceDetail(serviceID)),
});

export default function EditInternalServicePage({
  hotelId,
  serviceId,
}: RouteComponentProps<EditInternalServicePageProps>) {
  useInjectReducer({ key: 'externalService', reducer });
  useInjectSaga({ key: 'externalService', saga });

  const dispatch = useDispatch();
  const { serviceDetail, isLoading } = useSelector(mapStateToProps);
  const { doFetchServiceDetail } = mapDispatchToProps(dispatch);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { collapsed, cityID } = useSelectDashboardState();
  const firstLoading = useRef<boolean>(false);

  useEffect(() => {
    if (firstLoading.current) {
      navigate('/configure-hotels');
    }
    firstLoading.current = true;
  }, [cityID]); // eslint-disable-line

  const [initialValues, setInitialValues] = useState<UpdateServiceRequest>({
    images: [],
  });

  useEffect(() => {
    if (serviceDetail) {
      setInitialValues(serviceDetail as UpdateServiceRequest);
    }
  }, [serviceDetail]);

  useEffect(() => {
    serviceId && doFetchServiceDetail(serviceId);
  }, [serviceId]); // eslint-disable-line

  async function onSubmit(body: UpdateServiceRequest) {
    try {
      //remove extra params that we're using to input items
      delete body.addEn;
      delete body.addVi;
      delete body.addZh;
      const props = body as ServiceUpdateServiceRequest;

      setIsSubmitting(true);
      const newBody: ServiceUpdateServiceRequest = {
        ...props,
        defaultImage:
          Array.isArray(props.images) && props.images.length >= 1
            ? props.images[0]
            : '',
      };

      await coreAPI.put(`v1/operation/services/${serviceId}`, newBody);
      showSuccessNotification(
        'The internal service has been saved successfully ',
      );
    } catch (e) {
      showErrorNotification('Cannot update internal service', e);
    } finally {
      setIsSubmitting(false);
      setInitialValues(body);
    }
  }

  return (
    <Form<UpdateServiceRequest>
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      validate={validate}
      mutators={{
        ...arrayMutators,
        clearField: (args, state, { changeValue }) =>
          changeValue(state, args[0], () => ''),
      }}
      initialValues={initialValues}
      render={({ handleSubmit, form: { reset } }) => (
        <Container id="edit-internal-service-form" onSubmit={handleSubmit}>
          <PageHeader title="Edit Internal Service" />
          {isLoading ? (
            <div className="bg-white px-4">
              <SkeletonTable />
            </div>
          ) : (
            <ServiceForm isSubmitting={isSubmitting} isInternal />
          )}
          <Layout.Footer
            collapsed={collapsed}
            extraRight={
              <div className="inline-block">
                <Button
                  className="w-32"
                  size="large"
                  type="line"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() =>
                    hotelId &&
                    navigate(formatRoute(ROUTES.INTERNAL_SERVICES, hotelId))
                  }
                >
                  Cancel
                </Button>
                <Button
                  className="w-32 ml-4"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    document
                      ?.getElementById('edit-internal-service-form')
                      ?.dispatchEvent(
                        new Event('submit', { cancelable: true }),
                      );
                  }}
                >
                  Save
                </Button>
              </div>
            }
          ></Layout.Footer>
        </Container>
      )}
    ></Form>
  );
}
