import React, { useState, useEffect, useRef } from 'react';
import { PageHeader, Button, Layout, PopupConfirm } from '@aha/ui';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from 'styled-components';
import { validate } from './validator';
import ServiceForm from 'components/ServiceForm';
import { ServiceCreateServiceRequest } from 'types/schema';
import useSelectDashboardState from 'containers/Dashboard/useSelectDashboardState';
import { coreAPI } from 'utils/request';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';
import { RouteComponentProps, navigate } from '@reach/router';

const Container = styled.form`
  margin-bottom: 1rem;
  .ant-row.ant-form-item {
    width: 100%;
  }
`;

interface CreateInternalServicePageProps {
  hotelId: string;
}

export type CreateServiceRequest = Partial<ServiceCreateServiceRequest> & {
  addEn?: string;
  addZh?: string;
  addVi?: string;
};

export default function CreateInternalServicePage({
  hotelId,
}: RouteComponentProps<CreateInternalServicePageProps>) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { collapsed, cityID } = useSelectDashboardState();
  const firstLoading = useRef<boolean>(false);

  const initialValue: CreateServiceRequest = {
    images: [],
    price: 0,
    shortDescriptionEn: [''],
    shortDescriptionVi: [''],
    shortDescriptionZh: [''],
  };

  const [initialValues, setInitialValues] = useState<CreateServiceRequest>(
    initialValue,
  );

  useEffect(() => {
    if (firstLoading.current) {
      navigate('/configure-hotels');
    }
    firstLoading.current = true;
  }, [cityID]);

  async function onSubmit(body: CreateServiceRequest) {
    try {
      //remove extra params that we're using to input items
      delete body.addEn;
      delete body.addVi;
      delete body.addZh;
      const props = body as ServiceCreateServiceRequest;

      setIsSubmitting(true);
      const newBody: ServiceCreateServiceRequest = {
        ...props,
        type: 'internal',
        hotelID: hotelId,
        defaultImage:
          Array.isArray(props.images) && props.images.length >= 1
            ? props.images[0]
            : '',
      };

      await coreAPI.post('v1/operation/services', newBody);

      showSuccessNotification(
        'The internal service has been created successfully ',
      );
    } catch (e) {
      showErrorNotification('Cannot create internal service', e);
    } finally {
      setIsSubmitting(false);
      setInitialValues(body);
    }
  }

  return (
    <Form<CreateServiceRequest>
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      validate={validate}
      mutators={{
        ...arrayMutators,
        clearField: (args, state, { changeValue }) =>
          changeValue(state, args[0], () => ''),
      }}
      initialValues={initialValues}
      render={({ handleSubmit, form: { reset } }) => (
        <Container id="create-internal-service-form" onSubmit={handleSubmit}>
          <PageHeader title="Create Internal Service" />
          <ServiceForm isSubmitting={isSubmitting} isInternal />
          <Layout.Footer
            collapsed={collapsed}
            extraLeft={
              <PopupConfirm
                placement="topRight"
                title="Are you going to reset this service?"
                onCancel={() => {
                  reset(initialValues);
                  setInitialValues(initialValue);
                }}
                okText="No"
                cancelText="Yes"
              >
                <Button
                  type="line"
                  color="secondary"
                  size="large"
                  className="bg-grey-light border-none hover:bg-secondary"
                  disabled={isSubmitting}
                >
                  Reset
                </Button>
              </PopupConfirm>
            }
            extraRight={
              <Button
                className="w-32"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={() => {
                  document
                    ?.getElementById('create-internal-service-form')
                    ?.dispatchEvent(new Event('submit', { cancelable: true }));
                }}
              >
                Create
              </Button>
            }
          ></Layout.Footer>
        </Container>
      )}
    ></Form>
  );
}
