import React, { Suspense, useMemo } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import useAppIsReady from 'hooks/useAppIsReady';
import { Layout } from '@aha/ui';
import {
  LayoutSidebarMenu,
  LayoutSidebarMenuItem,
} from '@aha/ui/src/components/Layout/Sidebar';
import { useDispatch } from 'react-redux';
import { logout } from 'containers/Auth/actions';
import { navigate } from '@reach/router';
import DashboardLoader from 'components/loaders/DashboardLoader';
import PageTableLoader from 'components/loaders/PageTableLoader';
import useSelectDashboardState from './useSelectDashboardState';
import { ReactComponent as LogoutIcon } from './logout.svg';
import { useMedia } from '@aha/utils';
import LocationDropdown from 'containers/SelectLocation/LocationDropdown';
import useSelectLocations from 'containers/SelectLocation/useSelectLocations';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import reducer from './reducer';
import { useInjectReducer } from 'utils/injectReducer';

const menu: LayoutSidebarMenu = [
  {
    section: 'Main',
    id: 'main',
    menu: [
      {
        id: 'hotel.config_list',
        link: ROUTES.HOTEL_CONFIG_LIST,
        name: 'Configure Hotel',
        icon: 'cog',
      },
      {
        id: 'extra.external_service',
        link: ROUTES.EXTERNAL_SERVICES,
        name: 'External services',
        icon: 'hotel',
      },
    ],
  },
  {
    section: 'Settings',
    id: 'settings',
    menu: [
      {
        id: 'settings.loyalty',
        link: ROUTES.LOYALTY_SETTINGS,
        name: 'Customize Loyalty',
        icon: 'cog',
      },
    ],
  },
];

const Dashboard: React.FunctionComponent<RouteComponentProps> = ({
  children,
  location,
}) => {
  useInjectSaga({ key: 'dashboard', saga });
  useInjectReducer({ key: 'dashboard', reducer });

  const { failedToFetch, isLoading, isLogin } = useAppIsReady();
  const {
    collapsed,
    cityID,
    toggleCollapsed,
    doSwitchCurrentCityId,
  } = useSelectDashboardState();

  const { locations } = useSelectLocations();

  const isOnMobile = useMedia('lg');
  const dispatch = useDispatch();

  const selectedKey = useMemo(() => {
    const item = menu
      .reduce((acc, cur) => {
        if (cur.menu) {
          return [...acc, ...cur.menu];
        }
        return acc;
      }, [] as LayoutSidebarMenuItem[])
      .filter(({ link }) => location?.pathname.startsWith(link || '*'))[0];
    return item?.id;
  }, [location?.pathname]); // eslint-disable-line

  if (!cityID && locations) {
    doSwitchCurrentCityId(locations[0]?.id as string);
  }

  if (!isLogin) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }

  if (isLoading || !cityID) {
    return <DashboardLoader />;
  }

  if (failedToFetch) {
    return null;
  }

  return (
    <>
      <Layout>
        <Layout.Header
          toggleCollapsed={toggleCollapsed}
          extraRight={
            <div className="inline-flex">
              <LocationDropdown
                locations={locations || []}
                current={(locations || []).find(l => l.id === cityID)}
                onItemClick={doSwitchCurrentCityId}
              />
              <button
                onClick={() => dispatch(logout())}
                className="ml-2 bg-transparent inline-flex items-center px-2 outline-none hover:text-primary"
              >
                <LogoutIcon className="mr-2" />
                Logout
              </button>
            </div>
          }
        ></Layout.Header>
        <Layout>
          <Layout.Sidebar
            isOnMobile={isOnMobile}
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
            menu={menu}
            onMobileMenuClose={toggleCollapsed}
            selectedItem={selectedKey}
            onItemClick={item => {
              if (item.external) {
                // TODO: not recommend
                // more info https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window-using-javascript
                const win = window.open(item.link, '_blank');
                win && win.focus();
                return;
              }
              navigate(item.link);
            }}
          ></Layout.Sidebar>
          <Layout>
            <Layout.Content>
              <Suspense fallback={<PageTableLoader />}>{children}</Suspense>
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Dashboard;
