import { LocationsState, LocationsAction } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: LocationsState = {
  loading: false,
  error: null,
  locations: null,
};

const postReducer = (
  state: LocationsState = initialState,
  action: LocationsAction,
): LocationsState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_LOCATIONS:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_LOCATIONS_SUCCESS:
        draft.loading = false;
        draft.locations = action.payload;
        break;
      case ActionTypes.FETCH_LOCATIONS_FAILURE:
        draft.loading = false;
        break;
    }
  });

export default postReducer;
