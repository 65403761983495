import React from 'react';
import Routes from './Routes';
import { Provider } from 'react-redux';
import configureStore from 'configureStore';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body, html {
    background-color: var(--grey-lighter)!important;
  }
`;

export const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <GlobalStyles />
      <Routes />
    </Provider>
  );
};

export default App;
