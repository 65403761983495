import { takeLatest, call, all, put } from 'redux-saga/effects';
import NProgress from 'nprogress';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { HotelConfigActions } from './types';
import { coreAPI } from 'utils/request';
import {
  fetchHotelConfigListFailure,
  fetchHotelConfigListSuccess,
  fetchDistrictbyCityIDFailure,
  fetchDistrictbyCityIDSuccess,
} from './actions';
import { HotelGetHotelConfigurationResponse } from 'types/schema';
import { DistrictListResponse } from './actions';

export function* doFetchHotelConfigList(
  action: ExtractAction<
    HotelConfigActions,
    ActionTypes.FETCH_HOTEL_CONFIG_LIST
  >,
) {
  NProgress.start();
  try {
    const resp: HotelGetHotelConfigurationResponse = yield call(
      coreAPI.get,
      `/v1/operation/configs/hotels?cityID=${action.payload.cityId}`,
    );
    yield put(fetchHotelConfigListSuccess(resp));
  } catch (err) {
    yield put(fetchHotelConfigListFailure(err));
  } finally {
    NProgress.done();
  }
}

export function* watchFetchHotelConfigList() {
  yield takeLatest(ActionTypes.FETCH_HOTEL_CONFIG_LIST, doFetchHotelConfigList);
}

export function* doFetchDistrictList(
  action: ExtractAction<HotelConfigActions, ActionTypes.FETCH_DISTRICT>,
) {
  NProgress.start();
  try {
    const { data }: DistrictListResponse = yield call(
      coreAPI.get,
      `/v1/shared/cities/${action.payload.cityId}/districts`,
    );
    yield put(fetchDistrictbyCityIDSuccess(data!));
  } catch (err) {
    yield put(fetchDistrictbyCityIDFailure(err));
  } finally {
    NProgress.done();
  }
}

export function* watchfetchDistrictList() {
  yield takeLatest(ActionTypes.FETCH_DISTRICT, doFetchDistrictList);
}
export default function* watchHotelConfigAll() {
  yield all([watchFetchHotelConfigList(), watchfetchDistrictList()]);
}
