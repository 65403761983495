import { UnitsState, UnitsAction } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: UnitsState = {
  loading: false,
  error: null,
  units: null,
};

const unitsReducer = (
  state: UnitsState = initialState,
  action: UnitsAction,
): UnitsState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_UNITS:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_UNITS_SUCCESS:
        draft.loading = false;
        draft.units = action.payload;
        break;
      case ActionTypes.FETCH_UNITS_FAILURE:
        draft.loading = false;
        break;
    }
  });

export default unitsReducer;
