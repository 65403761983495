enum ActionTypes {
  FETCH_CATEGORIES = 'categories/FETCH_CATEGORIES',
  FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'categories/FETCH_CATEGORIES_FAILURE',

  FETCH_HOTEL_CATEGORIES = 'categories/FETCH_HOTEL_CATEGORIES',
  FETCH_HOTEL_CATEGORIES_SUCCESS = 'categories/FETCH_HOTEL_CATEGORIES_SUCCESS',
  FETCH_HOTEL_CATEGORIES_FAILURE = 'categories/FETCH_HOTEL_CATEGORIES_FAILURE',
}

export default ActionTypes;
