import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectLoyaltySettingsState = (state: ApplicationRootState) => {
  return state.loyaltySettings || initialState;
};

const makeSelectIsLoading = () =>
  createSelector(
    selectLoyaltySettingsState,
    externalServiceState => externalServiceState.isLoading,
  );

const makeSelectError = () =>
  createSelector(
    selectLoyaltySettingsState,
    externalServiceState => externalServiceState.error,
  );

const makeSelectLoyaltySettings = () =>
  createSelector(
    selectLoyaltySettingsState,
    externalServiceState => externalServiceState.loyalty,
  );

export { makeSelectError, makeSelectLoyaltySettings, makeSelectIsLoading };
