import { makeSelectLocations } from './selectors';
import { ApplicationRootState } from 'types/app';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import reducer from './reducer';
import saga from './saga';
import { useInjectReducer } from 'utils/injectReducer';
import { Dispatch } from 'redux';
import { fetchLocations } from './actions';
import { useInjectSaga } from 'utils/injectSaga';
import { useEffect } from 'react';
import { CityCity } from 'types/schema';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    locations: CityCity[] | null;
  }
>({
  locations: makeSelectLocations(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchLocations: () => dispatch(fetchLocations()),
});

export default function useSelectLocations() {
  useInjectSaga({ key: 'locations', saga });
  useInjectReducer({ key: 'locations', reducer });

  const dispatch = useDispatch();
  const { doFetchLocations } = mapDispatchToProps(dispatch);
  const { locations } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!locations) {
      doFetchLocations();
    }
  }, [locations]); // eslint-disable-line

  return useSelector(mapStateToProps);
}
