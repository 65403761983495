import React from 'react';
import { SkeletonTable } from '@aha/ui';
import HeaderLoader from './HeaderLoader';

const PageTableLoader = () => {
  return (
    <>
      <HeaderLoader />
      <div className="bg-white px-6">
        <SkeletonTable />
      </div>
    </>
  );
};

export default PageTableLoader;
