import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Icon from '../Icon';

export type MembershipType = 'non-member' | 'member' | 'vip-member';

const StyledBadge = styled.span<{ noText: boolean }>`
  ${tw`inline-flex justify-center items-center text-sm rounded-sm h-6 px-1 text-center`};
  width: ${props => (props.noText ? '28px' : 'auto')};
  padding-right: ${props => (props.noText ? '4px' : '7px')};
  svg {
    margin-right: ${props => (props.noText ? 0 : 6)}px;
  }
`;

const GuestBadge = styled(StyledBadge)`
  ${tw`text-secondary bg-grey-lighter`};
`;

const MemberBadge = styled(StyledBadge)`
  ${tw`text-green`};
  background-color: #edf9f0;
`;

const VipBadge = styled(StyledBadge)`
  ${tw`text-orange bg-red-lightest`};
`;

const components: { [k in MembershipType]: React.ElementType } = {
  'non-member': GuestBadge,
  member: MemberBadge,
  'vip-member': VipBadge,
};

export type MembershipBadgeProps = {
  type?: MembershipType;
  text?: React.ReactNode;
  noText?: boolean;
  className?: string;
};

export const MembershipBadge: React.FC<MembershipBadgeProps> = ({
  type = 'non-member',
  text = '',
  noText = false,
  className,
}) => {
  const RenderBadge = components[type];
  let renderText: React.ReactNode;

  if (type === 'non-member') {
    renderText = 'None';
  } else if (type === 'member') {
    renderText = 'Member';
  } else if (type === 'vip-member') {
    renderText = 'Vip';
  }

  if (text) {
    renderText = text;
  }

  return (
    <RenderBadge className={className} noText={noText}>
      <Icon type={type === 'non-member' ? 'times-circle' : 'crown'} />
      {!noText && renderText}
    </RenderBadge>
  );
};

export default MembershipBadge;
