import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/SelectServiceCategories/saga';
import reducer from 'containers/SelectServiceCategories/reducer';
import {
  makeSelectCategoriesLoading,
  makeSelectCategories,
} from 'containers/SelectServiceCategories/selectors';

import { useInjectReducer } from 'utils/injectReducer';
import { fetchCategories } from './actions';
import { ServicecategoryCategory } from 'types/schema';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    categories: ServicecategoryCategory[] | null;
    categoriesLoading: boolean;
  }
>({
  categories: makeSelectCategories(),
  categoriesLoading: makeSelectCategoriesLoading(),
});

const mapDispatchToProps = (dispatch: any) => ({
  doFetchChannels: () => dispatch(fetchCategories()),
});

export default function useSelectCategories() {
  useInjectSaga({ key: 'categories', saga });
  useInjectReducer({ key: 'categories', reducer });

  const dispatch = useDispatch();
  const { doFetchChannels } = mapDispatchToProps(dispatch);
  const { categories } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!categories) {
      doFetchChannels();
    }
  }, []); // eslint-disable-line

  return useSelector(mapStateToProps);
}
