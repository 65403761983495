import React from 'react';
import useSelectCategories from './useSelectServiceCategories';
import { FormSelect } from '@aha/ui';
import { FormSelectOptions } from '@aha/ui/src/components/FormSelect';

export interface FormSelectCategoriesProps {
  name: string;
  label?: string;
  className?: string;
}

const FormSelectCategories = (props: FormSelectCategoriesProps) => {
  const { categories } = useSelectCategories();
  return (
    <FormSelect
      className="w-full"
      placeholder="Select"
      {...props}
      options={
        (categories || []).map(({ name, id }) => ({
          name,
          value: id,
        })) as FormSelectOptions
      }
    />
  );
};

export default FormSelectCategories;
