import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectProvidersState = (state: ApplicationRootState) => {
  return state.providers || initialState;
};

const makeSelectProvidersLoading = () =>
  createSelector(
    selectProvidersState,
    providersState => providersState.loading,
  );

const makeSelectProvidersError = () =>
  createSelector(selectProvidersState, providersState => providersState.error);

const makeSelectProviders = () =>
  createSelector(
    selectProvidersState,
    providersState => providersState.providers,
  );

export {
  makeSelectProviders,
  makeSelectProvidersError,
  makeSelectProvidersLoading,
};
