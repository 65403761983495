import React from 'react';
import useSelectServiceProviders from './useSelectServiceProviders';
import { FormSelect } from '@aha/ui';
import { FormSelectOptions } from '@aha/ui/src/components/FormSelect';

export interface FormSelectProvidersProps {
  name: string;
  label?: string;
  className?: string;
  placeholder?: string;
}

const FormSelectProviders = (props: FormSelectProvidersProps) => {
  const { providers, providersLoading } = useSelectServiceProviders();
  return (
    <FormSelect
      className="w-full"
      disabled={providersLoading}
      {...props}
      options={
        (providers || []).map(({ name, id }) => ({
          name: name,
          value: id,
        })) as FormSelectOptions
      }
    />
  );
};

export default FormSelectProviders;
