import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { CityCity } from 'types/schema';

export const fetchLocations = () => action(ActionTypes.FETCH_LOCATIONS);

export const fetchLocationsSuccess = (locations: CityCity[]) =>
  action(ActionTypes.FETCH_LOCATIONS_SUCCESS, locations);

export const fetchLocationsFailure = (error: Error) =>
  action(ActionTypes.FETCH_LOCATIONS_FAILURE, error);
