import { DashboardActions, DashboardState } from './types';

import ActionTypes from './constants';
import produce from 'immer';

export const initialState: DashboardState = {
  collapsed: false,
  cityID: window.localStorage.getItem('cid') as string,
};

const developReducer = (
  state: DashboardState = initialState,
  action: DashboardActions,
): DashboardState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.DASHBOARD_TOGGLE_COLLAPSED:
        draft.collapsed = !draft.collapsed;
        break;
      case ActionTypes.SWITCH_CURRENT_CITY_SUCCESS:
        draft.cityID = action.payload;
        break;
    }
  });

export default developReducer;
