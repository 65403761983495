import { ExternalServiceActions, ExternalServiceState } from './types';

import ActionTypes from './constants';
import produce from 'immer';
import initialStateHolder from 'utils/initialStateHolder';

export const initialState = initialStateHolder.setState<ExternalServiceState>(
  'externalService',
  {
    isLoading: false,
    error: null,
    externalServices: null,
    hotelList: null,
    isServiceDetailLoading: false,
    serviceDetail: null,
  },
);

const externalServiceReducer = (
  state: ExternalServiceState = initialState,
  action: ExternalServiceActions,
): ExternalServiceState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL:
        draft.isServiceDetailLoading = true;
        break;

      case ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL_SUCCESS:
        draft.serviceDetail = action.payload;
        draft.isServiceDetailLoading = false;
        break;

      case ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL_FAILURE:
        draft.isServiceDetailLoading = false;
        break;
      case ActionTypes.FETCH_HOTEL_LIST_ASSIGN:
        draft.isLoading = true;
        break;
      case ActionTypes.FETCH_HOTEL_LIST_ASSIGN_SUCCESS:
        draft.isLoading = false;
        if (action.payload.Data) draft.hotelList = action.payload.Data;
        break;
    }
  });

export default externalServiceReducer;
