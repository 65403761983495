import React, { useState, useEffect } from 'react';
import { PageHeader, SkeletonTable, Card, Table, Button, Icon } from '@aha/ui';
import { createStructuredSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types/app';
import EditSettingModal from './EditSettingModal';
import { makeSelectIsLoading, makeSelectLoyaltySettings } from './selectors';
import { fetchLoyaltySettings, editLoyaltySettings } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import currencyFormater from '@aha/utils/src/currencyFormatter';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import reducer from './reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { ModelCoin } from 'types/schema';
import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';

export const Cell = styled.div`
  ${tw`inline-flex items-center px-6 py-2`}
`;

export const Wrapper = styled.div`
  ${tw`w-full flex flex-wrap border-b font-medium border rounded-sm`}
  min-height: 3.5rem;
`;

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    loyalty: ModelCoin | null;
    isLoading: boolean;
  }
>({
  loyalty: makeSelectLoyaltySettings(),
  isLoading: makeSelectIsLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchLoyaltySettings: () => dispatch(fetchLoyaltySettings()),
  doEditLoyaltySettings: (loyalty: ModelCoin) =>
    new Promise((resolve, reject) =>
      dispatch(editLoyaltySettings({ data: loyalty, resolve, reject })),
    ),
});

export type Schema = {
  key?: string;
  title: React.ReactNode;
  daily: React.ReactNode;
  hourly: React.ReactNode;
};

const columns: ColumnProps<Schema>[] = [
  {
    title: 'For daily',
    dataIndex: 'daily',
    width: '30%',
    align: 'center',
    className: 'font-medium whitespace-normal',
    render: (node: React.ReactNode) => node,
  },
  {
    title: 'For hourly',
    dataIndex: 'hourly',
    align: 'center',
    className: 'font-medium whitespace-normal',
    render: (node: React.ReactNode) => node,
  },
];

export const earnedCoinsCols: ColumnProps<Schema>[] = [
  {
    title: (
      <div className="inline-flex items-center">
        Regulations on coins earned
        <Tooltip title="The value converted into a coin" arrowPointAtCenter>
          <Icon
            type="note-circle"
            className="ml-2 text-base text-grey-darker"
          />
        </Tooltip>
      </div>
    ),
    width: '40%',
    dataIndex: 'title',
    align: 'left',
    className: 'font-medium whitespace-normal',
  },
  ...columns,
];

export const usCoinsCols: ColumnProps<Schema>[] = [
  {
    title: 'Regulation on using coins',
    width: '40%',
    dataIndex: 'title',
    align: 'left',
    className: 'font-medium whitespace-normal',
  },
  ...columns,
];

export default function EditInternalServicePage() {
  useInjectReducer({ key: 'loyaltySettings', reducer });
  useInjectSaga({ key: 'loyaltySettings', saga });

  const dispatch = useDispatch();
  const { isLoading, loyalty } = useSelector(mapStateToProps);
  const { doFetchLoyaltySettings, doEditLoyaltySettings } = mapDispatchToProps(
    dispatch,
  );
  const [submitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);

  const {
    earningRate,
    exchangeRate,
    hotelPayableRate,
    hourlyBookingEarningRate,
    hourlyBookingMaxEarning,
    hourlyBookingMaxExpense,
    maxEarning,
    maxExpense,
    useableAfter,
    validTime,
  } = loyalty || {};

  useEffect(() => {
    doFetchLoyaltySettings();
  }, []); // eslint-disable-line

  const eData: Schema[] = [
    {
      key: 'earned_coins_1',
      title: 'Percent per booking',
      daily: `${(earningRate || 0) * 100}%`,
      hourly: `${(hourlyBookingEarningRate || 0) * 100}%`,
    },
    {
      key: 'earned_coins_2',
      title: 'Max coins earned each booking',
      daily: `${maxEarning || 0} coins`,
      hourly: `${hourlyBookingMaxEarning || 0} coins`,
    },
  ];

  const uData: Schema[] = [
    {
      key: 'using_coins',
      title: (
        <div className="inline-flex items-center">
          Max coins burn for a room
          <Tooltip
            title="How many coins to discount when paying?"
            arrowPointAtCenter
          >
            <Icon
              type="note-circle"
              className="ml-2 text-base text-grey-darker"
            />
          </Tooltip>
        </div>
      ),
      daily: `${maxExpense || 0} coins`,
      hourly: `${hourlyBookingMaxExpense || 0} coins`,
    },
  ];

  async function onEdit(loyalty: ModelCoin) {
    try {
      const {
        earningRate = 0,
        hourlyBookingEarningRate = 0,
        ...rest
      } = loyalty;

      const body = {
        ...rest,
        earningRate: earningRate / 100,
        hourlyBookingEarningRate: hourlyBookingEarningRate / 100,
      };

      setSubmitting(true);
      await doEditLoyaltySettings(body);
      setVisible(false);
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <PageHeader
        title="Customize loyalty"
        extra={
          <Button color="primary" onClick={() => setVisible(true)}>
            Edit
          </Button>
        }
      />
      {isLoading ? (
        <div className="bg-white px-4">
          <SkeletonTable />
        </div>
      ) : (
        <Card>
          <section className="pb-6">
            <Wrapper>
              <Cell className="w-2/5 border-r font-medium">
                1 coins used to discount when making payment equivalent to how
                much money?
              </Cell>
              <Cell className="w-3/5 justify-center">
                {currencyFormater(exchangeRate)}
              </Cell>
            </Wrapper>
          </section>
          <section className="pb-6">
            <Table
              bordered
              borderBottom
              columns={usCoinsCols}
              dataSource={uData}
              pagination={false}
            />
          </section>
          <section className="pb-6">
            <Table
              bordered
              borderBottom
              columns={earnedCoinsCols}
              dataSource={eData}
              pagination={false}
            />
          </section>
          <section className="pb-6">
            <Wrapper>
              <Cell className="w-2/5 border-r font-medium">
                How long the number of Coins that customers earned is expired?
              </Cell>
              <Cell className="w-3/5 justify-center">
                {`${validTime} Days`}
              </Cell>
            </Wrapper>
          </section>
          <section className="pb-6">
            <Wrapper>
              <Cell className="w-2/5 border-r font-medium">
                Valid time for new coins earned to be used since the booking was
                checked out
              </Cell>
              <Cell className="w-3/5 justify-center">{`${useableAfter} hours`}</Cell>
            </Wrapper>
          </section>
          <section className="pb-6">
            <Wrapper>
              <Cell className="w-2/5 border-r font-medium">
                The maximum amount the hotel will pay for a room when using
                coins for payment
              </Cell>
              <Cell className="w-3/5 justify-center">
                {currencyFormater(hotelPayableRate)}
              </Cell>
            </Wrapper>
          </section>
        </Card>
      )}
      <EditSettingModal
        visible={visible}
        data={loyalty || {}}
        onSubmit={onEdit}
        submitting={submitting}
        onCancel={() => setVisible(false)}
      />
    </>
  );
}
