import React from 'react';
import ComponentRoute, { ComponentRouteProps } from 'components/ComponentRoute';
import { useInjectSaga } from 'utils/injectSaga';
import authSaga from 'containers/Auth/saga';
import { Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import {
  makeSelectIsLogin,
  makeSelectIsSubmitting,
} from 'containers/Auth/selectors';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  { isLogin: boolean; isSubmitting: boolean }
>({
  isLogin: makeSelectIsLogin(),
  isSubmitting: makeSelectIsSubmitting(),
});

export default function PublicRoute(props: ComponentRouteProps) {
  useInjectSaga({ key: 'auth', saga: authSaga });

  const { isLogin } = useSelector(mapStateToProps);

  if (isLogin) {
    return <Redirect to={ROUTES.DASHBOARD} noThrow />;
  }

  return <ComponentRoute {...props} />;
}
