import React, { ReactElement } from 'react';
import { Button, FormInput } from '@aha/ui';
import { FormSpy } from 'react-final-form';
import { LANGUAGE_TYPES, Block } from './MainContent';

interface ContentItemProps {
  name: string;
  index: number;
  label: string;
}

export function ContentItem({
  name,
  index,
  label,
}: ContentItemProps): ReactElement {
  return (
    <div className="inline-flex w-1/2 mt-1 relative">
      <FormInput className="w-full" name={name} label={label} />
      {index === 0 ? (
        <Block className="hidden md:block" />
      ) : (
        <FormSpy
          subscription={{ values: true }}
          render={({
            form: {
              mutators: { remove },
            },
          }) => (
            <Button
              size="small"
              type="icon"
              icon="remove-cirle"
              className="ml-2 mt-6 sm:mt-7 -mr-8 md:mr-0 ml-2 text-secondary absolute md:relative pin-r"
              onClick={() => {
                LANGUAGE_TYPES.forEach(t => {
                  remove(`shortDescription${t}`, index);
                });
              }}
            />
          )}
        />
      )}
    </div>
  );
}

export default React.memo(ContentItem);
