import React from 'react';
import { Pagination as AntPagination } from 'antd';
import { PaginationProps as AntPaginationProps } from 'antd/lib/pagination';
import { Select } from '@aha/ui';

export const PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];
export const DEFAULT_PAGE_NUMBER = 1;

export interface PaginationProps extends AntPaginationProps {
  className: string;
  pageNumber?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  className,
  pageSize,
  total,
  pageNumber,
  onPageChange,
  onPageSizeChange,
}) => (
  <div className={className}>
    <AntPagination
      size="small"
      hideOnSinglePage
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      onChange={onPageChange}
    />
    <Select
      className="w-16 ml-2"
      defaultValue={pageSize}
      onChange={onPageSizeChange}
    >
      {PAGE_SIZE_OPTIONS.map(c => (
        <Select.Option key={c} value={c}>
          {c}
        </Select.Option>
      ))}
    </Select>
    <span className="ml-2">items per page</span>
    <span className="w-40 inline-flex justify-end">
      {(Number(pageNumber) - 1) * Number(pageSize) + 1} -{' '}
      {Number(pageNumber) * Number(pageSize) < (total || 10)
        ? Number(pageNumber) * Number(pageSize)
        : total}{' '}
      of {total} items
    </span>
  </div>
);

export default Pagination;
