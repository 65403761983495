import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/SelectServiceProviders/saga';
import reducer from 'containers/SelectServiceProviders/reducer';
import {
  makeSelectProvidersLoading,
  makeSelectProviders,
} from 'containers/SelectServiceProviders/selectors';

import { useInjectReducer } from 'utils/injectReducer';
import { fetchProviders } from './actions';
import { ProviderProvider } from 'types/schema';
import { Dispatch } from 'redux';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    providers: ProviderProvider[] | null;
    providersLoading: boolean;
  }
>({
  providers: makeSelectProviders(),
  providersLoading: makeSelectProvidersLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchProviders: (providerType?: string) =>
    dispatch(fetchProviders(providerType)),
});

export default function useSelectProviders(providerType?: string) {
  useInjectSaga({ key: 'providers', saga });
  useInjectReducer({ key: 'providers', reducer });

  const dispatch = useDispatch();
  const { doFetchProviders } = mapDispatchToProps(dispatch);
  const { providers } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!providers) {
      doFetchProviders(providerType);
    }
  }, []); // eslint-disable-line

  return useSelector(mapStateToProps);
}
