import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { Icon } from '../Icon';
import { InputProps } from 'antd/lib/input';

const StyledInput = styled(Input)`
  .ant-input-suffix {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8px !important;
  }

  .anticon {
    height: 24px;
    width: 24px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: var(--white);
    top: 50%;
    transform: translateY(-50%);
  }
`;
export interface SearchProps extends InputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  enterButton?: React.ReactNode;
  onSearch?: () => void;
}

export const InputSearch: React.FC<SearchProps> = ({
  placeholder,
  onChange,
  ...rest
}) => {
  return (
    <StyledInput
      placeholder={placeholder}
      suffix={<Icon type="search" />}
      allowClear
      onChange={onChange}
      {...rest}
    />
  );
};

export default InputSearch;
