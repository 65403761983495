import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ServiceProvider } from 'types/schema';

export const fetchProviders = (providerType?: string) =>
  action(ActionTypes.FETCH_PROVIDERS, providerType);

export const fetchProvidersSuccess = (providers: ServiceProvider[]) =>
  action(ActionTypes.FETCH_PROVIDERS_SUCCESS, providers);

export const fetchProvidersFailure = (error: Error) =>
  action(ActionTypes.FETCH_PROVIDERS_FAILURE, error);
