import { takeLatest, call, all, put } from 'redux-saga/effects';
import NProgress from 'nprogress';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { AuthActions } from './types';
import { coreAPI, setToken, removeToken } from 'utils/request';
import {
  setLoginSuccess,
  setLoginFailure,
  logout,
  fetchUserSuccess,
} from './actions';
import { HttpLoginResponse } from 'types/schema';
import { switchCurrentCityId } from 'containers/Dashboard/actions';
import { fetchLocationsSuccess } from 'containers/SelectLocation/actions';

export function* doLogin(
  action: ExtractAction<AuthActions, ActionTypes.LOG_IN>,
) {
  const { payload } = action;
  NProgress.start();
  try {
    const resp: HttpLoginResponse = yield call(
      coreAPI.post,
      `v1/auth/login`,
      payload,
    );

    yield put(setLoginSuccess(resp.user!));
    yield call(setToken, resp.accessToken!);

    let cityID = window.localStorage.getItem('cid');
    if (!cityID) {
      const { data: cities } = yield call(coreAPI.get, '/v1/shared/cities');
      yield put(fetchLocationsSuccess(cities));
      cityID = (cities || [])[0].id;
    }

    yield put(switchCurrentCityId(cityID!));
  } catch (err) {
    yield call(removeToken);
    yield put(setLoginFailure(err.message));
  }
  NProgress.done();
}

// FIXME:
export function* doFetchUser() {
  NProgress.start();
  const url = 'v1/auth/me';
  try {
    const res = yield call(coreAPI.get, url);

    const user = {
      avatar: '',
      email: res.Data.Email,
      id: res.Data.ID,
      name: res.Data.Name,
      status: res.Data.Status,
    };
    yield put(fetchUserSuccess(user));
  } catch (err) {
    yield put(logout());
  }
  NProgress.done();
}

export function* doLogout() {
  yield call(removeToken);
}

export function* watchLogin() {
  yield takeLatest(ActionTypes.LOG_IN, doLogin);
}

export function* watchLogout() {
  yield takeLatest(ActionTypes.LOG_OUT, doLogout);
}

export function* watchFetchUser() {
  yield takeLatest(ActionTypes.FETCH_USER, doFetchUser);
}

export default function* watchAuthAll() {
  yield all([watchLogin(), watchLogout(), watchFetchUser()]);
}
