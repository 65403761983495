import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/SelectServiceUnits/saga';
import reducer from 'containers/SelectServiceUnits/reducer';
import {
  makeSelectUnitsLoading,
  makeSelectUnits,
} from 'containers/SelectServiceUnits/selectors';

import { useInjectReducer } from 'utils/injectReducer';
import { fetchUnits } from './actions';
import { ServiceUnit } from 'types/schema';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    units: ServiceUnit[] | null;
    unitsLoading: boolean;
  }
>({
  units: makeSelectUnits(),
  unitsLoading: makeSelectUnitsLoading(),
});

const mapDispatchToProps = (dispatch: any) => ({
  doFetchUnits: () => dispatch(fetchUnits()),
});

export default function useSelectUnits() {
  useInjectSaga({ key: 'units', saga });
  useInjectReducer({ key: 'units', reducer });

  const dispatch = useDispatch();
  const { doFetchUnits } = mapDispatchToProps(dispatch);
  const { units } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!units) {
      doFetchUnits();
    }
  }, []); // eslint-disable-line

  return useSelector(mapStateToProps);
}
