import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import {
  makeSelectDashboardCollapsed,
  makeSelectCurrentCityId,
} from './selectors';
import { Dispatch } from 'redux';
import { toggleDashboardCollapsed, switchCurrentCityId } from './actions';
import reducer from './reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    collapsed: boolean;
    cityID: string;
  }
>({
  collapsed: makeSelectDashboardCollapsed(),
  cityID: makeSelectCurrentCityId(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doSwitchCurrentCityId: (cityID: string) =>
    dispatch(switchCurrentCityId(cityID)),
  doToggleDashboardCollapsed: () => dispatch(toggleDashboardCollapsed()),
});

export default function useSelectDashboardState() {
  useInjectReducer({ key: 'dashboard', reducer });
  const dispatch = useDispatch();

  const {
    doToggleDashboardCollapsed,
    doSwitchCurrentCityId,
  } = mapDispatchToProps(dispatch);
  const { collapsed, cityID } = useSelector(mapStateToProps);

  return {
    collapsed,
    cityID,
    toggleCollapsed: doToggleDashboardCollapsed,
    doSwitchCurrentCityId,
  };
}
