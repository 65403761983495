import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ServiceService, HotelHotelListResponse } from 'types/schema';

export interface ChangeNumberHootelAssignPayload {
  serviceId: string;
  hotelAssign: number;
}

export const fetchExternalServiceDetail = (serviceID: string) => {
  return action(ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL, serviceID);
};

export const fetchExternalServiceDetailSuccess = (data: ServiceService) => {
  return action(ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL_SUCCESS, data);
};

export const fetchExternalServiceDetailFailure = (error: Error) =>
  action(ActionTypes.FETCH_EXTERNAL_SERVICE_DETAIL_FAILURE, error);

export const fetchHotelAssign = (payload: string) => {
  return action(ActionTypes.FETCH_HOTEL_LIST_ASSIGN, payload);
};

export const fetchHotelAssignSuccess = (data: HotelHotelListResponse) => {
  return action(ActionTypes.FETCH_HOTEL_LIST_ASSIGN_SUCCESS, data);
};
