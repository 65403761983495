import { call, put, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import ActionTypes from './constants';
import { fetchLocationsSuccess, fetchLocationsFailure } from './actions';
import { CityCityListResponse } from 'types/schema';

export function* fetchLocations() {
  try {
    const { data }: CityCityListResponse = yield call(
      coreAPI.get,
      '/v1/shared/cities',
    );

    yield put(fetchLocationsSuccess(data || []));
  } catch (err) {
    yield put(fetchLocationsFailure(err));
  }
}

export default function* watchFetchLocations() {
  yield takeLatest(ActionTypes.FETCH_LOCATIONS, fetchLocations);
}
