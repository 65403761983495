import { call, put, takeLatest, all } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import {
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  fetchHotelCategoriesFailure,
  fetchHotelCategoriesSuccess,
} from './actions';
import {
  ServicecategoryServiceCategoryListResponse,
  HotelGetCategoryListResponse,
} from 'types/schema';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { CategoriesAction } from './types';

export function* fetchCategories() {
  try {
    const { data }: ServicecategoryServiceCategoryListResponse = yield call(
      coreAPI.get,
      'v1/operation/service-categories',
    );

    yield put(fetchCategoriesSuccess(data!));
  } catch (err) {
    yield put(fetchCategoriesFailure(err));
  }
}

export function* watchFetchCategories() {
  yield takeLatest(ActionTypes.FETCH_CATEGORIES, fetchCategories);
}

export function* fetchHotelCategories(
  action: ExtractAction<CategoriesAction, ActionTypes.FETCH_HOTEL_CATEGORIES>,
) {
  try {
    const { payload: hotelID } = action;

    const { data }: HotelGetCategoryListResponse = yield call(
      coreAPI.get,
      `v1/operation/hotels/${hotelID}/service-categories`,
    );

    yield put(fetchHotelCategoriesSuccess(data || []));
  } catch (err) {
    yield put(fetchHotelCategoriesFailure(err));
  }
}

export function* watchHotelFetchCategories() {
  yield takeLatest(ActionTypes.FETCH_HOTEL_CATEGORIES, fetchHotelCategories);
}

export default function* watchAll() {
  yield all([watchFetchCategories(), watchHotelFetchCategories()]);
}
