enum ActionTypes {
  SET_LOYALTY_SETTINGS = 'loyaltySettings/SET_LOYALTY_SETTINGS',

  FETCH_LOYALTY_SETTINGS = 'loyaltySettings/FETCH_LOYALTY_SETTINGS',
  FETCH_LOYALTY_SETTINGS_SUCCESS = 'loyaltySettings/FETCH_LOYALTY_SETTINGS_SUCCESS',
  FETCH_LOYALTY_SETTINGS_FAILURE = 'loyaltySettings/FETCH_LOYALTY_SETTINGS_FAILURE',

  EDIT_LOYALTY_SETTINGS = 'loyaltySettings/EDIT_LOYALTY_SETTINGS',
  EDIT_LOYALTY_SETTINGS_SUCCESS = 'loyaltySettings/EDIT_SETTINGS_SUCCESS',
  EDIT_LOYALTY_SETTINGS_FAILURE = 'loyaltySettings/EDIT_SETTINGS_FAILURE',
}
export default ActionTypes;
