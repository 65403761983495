import React from 'react';
import styled from 'styled-components';

const ContainerWithMarginChild = styled.div`
  padding: 20px;
  > * {
    margin-right: 10px;
  }
`;

export const storyContainer = (storyFn: any) => (
  <div className="p-5">{storyFn()}</div>
);

export const storyContainerMarginChild = (storyFn: any) => (
  <ContainerWithMarginChild>{storyFn()}</ContainerWithMarginChild>
);

export const storyContainerGreyBackground = (storyFn: any) => (
  <div className="p-5 bg-grey-lighter min-h-screen">{storyFn()}</div>
);
