import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectHotelConfigListState = (state: ApplicationRootState) => {
  return state.hotelConfig || initialState;
};

const makeSelectIsLoading = () =>
  createSelector(
    selectHotelConfigListState,
    hotelConfigListState => hotelConfigListState.isLoading,
  );

const makeSelectError = () =>
  createSelector(
    selectHotelConfigListState,
    hotelConfigListState => hotelConfigListState.error,
  );

const makeSelectHotelConfig = () =>
  createSelector(
    selectHotelConfigListState,
    hotelConfigListState => hotelConfigListState.hotelConfigList!,
  );

const makeSelectDistrictList = () =>
  createSelector(
    selectHotelConfigListState,
    hotelConfigListState => hotelConfigListState.districtList!,
  );

export {
  makeSelectError,
  makeSelectHotelConfig,
  makeSelectIsLoading,
  makeSelectDistrictList,
};
