import { LoyaltySettingsActions, LoyaltySettingsState } from './types';

import ActionTypes from './constants';
import produce from 'immer';
import initialStateHolder from 'utils/initialStateHolder';

export const initialState = initialStateHolder.setState<LoyaltySettingsState>(
  'loyaltySettings',
  {
    isLoading: false,
    error: null,
    loyalty: null,
  },
);

const loyaltySettingsReducer = (
  state: LoyaltySettingsState = initialState,
  action: LoyaltySettingsActions,
): LoyaltySettingsState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_LOYALTY_SETTINGS:
        draft.isLoading = true;
        draft.error = null;
        break;

      case ActionTypes.FETCH_LOYALTY_SETTINGS_SUCCESS:
        draft.loyalty = action.payload;
        draft.isLoading = false;
        break;

      case ActionTypes.FETCH_LOYALTY_SETTINGS_FAILURE:
        draft.error = action.payload;
        draft.isLoading = false;
        break;

      case ActionTypes.EDIT_LOYALTY_SETTINGS_SUCCESS:
        draft.loyalty = action.payload;
        break;
    }
  });

export default loyaltySettingsReducer;
