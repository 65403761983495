import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectIsLogin, makeSelectLoginError } from './selectors';
import { ApplicationRootState } from 'types/app';
import { Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import { login } from './actions';
import { useInjectSaga } from 'utils/injectSaga';
import authSaga from './saga';
import { LoginLayout, LoginForm } from '@aha/ui';
import loginBackground from 'img/login-illustration.jpg';
import { HttpLoginRequest } from 'types/schema';

const mapDispatchToProps = (dispatch: any) => ({
  doLogin: (values: HttpLoginRequest) => dispatch(login(values)),
});

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  { isLogin: boolean; error: string }
>({
  isLogin: makeSelectIsLogin(),
  error: makeSelectLoginError(),
});

export default function LoginPage() {
  useInjectSaga({ key: 'auth', saga: authSaga });

  const { isLogin, error } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const { doLogin } = mapDispatchToProps(dispatch);

  if (isLogin) {
    return <Redirect to={ROUTES.HOME} noThrow />;
  }

  return (
    <LoginLayout
      name="Boost your hotel bookings and get more revenue"
      description="AHAROOMS is an independent hospitality software developer. AHAROOMS partners with many brands, but makes no claims upon their trademarks. All trademarks contained herein belong to their repective owners and registrants."
      image={loginBackground}
    >
      <div className="px-14 flex items-center h-full">
        <LoginForm onSubmit={doLogin} error={error} />
      </div>
    </LoginLayout>
  );
}
