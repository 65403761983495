import * as React from 'react';
import { Checkbox } from 'antd';
import { Button } from '../Button';
import { createGlobalStyle } from 'styled-components';
import tw from 'tailwind.macro';
import { FilterDropdownProps } from 'antd/lib/table';
import { CheckboxOptionType } from 'antd/lib/checkbox';

const FilterColumnStyle = createGlobalStyle`
.ant-table-filter-dropdown {
  &:before {
    ${tw`bg-white block absolute`};
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.025);
    content: '';
    top: -6px;
    right: 10px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }
}
`;

export function FilterColumn(
  dataIndex: string,
  options: Array<CheckboxOptionType | string>,
  okText?: string | React.ReactNode,
  resetText?: string | React.ReactNode,
) {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div className="p-2">
        <div>
          <Checkbox.Group
            className="flex flex-col pb-2"
            options={options}
            onChange={value => {
              setSelectedKeys && setSelectedKeys(value as string[]);
            }}
            value={selectedKeys}
          />
        </div>
        <div className="flex justify-end">
          <Button
            size="small"
            onClick={() => {
              if (confirm) confirm();
            }}
            className="mr-1"
          >
            {okText}
          </Button>
          <Button
            type="line"
            color="secondary"
            className="border-white hover:bg-transparent hover:text-secondary"
            size="small"
            onClick={() => {
              if (clearFilters) clearFilters([]);
            }}
          >
            {resetText}
          </Button>
        </div>
      </div>
    ),
    filterIcon: () => <Button icon="filter" type="icon" size="small" />,
    onFilter: (value: any, record: any) =>
      typeof value === 'string' &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  };
}

export { FilterColumnStyle };
export default FilterColumn;
