const ROUTES = {
  LOGIN: '/start/login',
  ERROR: '/404',
  DASHBOARD: '/',
  HOME: '/',
  HOTELS: '/hotels',
  EXTRA_SERVICES: '/extra-services',
  EDIT_EXTRA_SERVICES: '/extra-services/:id/edit',
  EXTERNAL_SERVICES_DETAIL: '/external-services/:id',
  CREATE_EXTRA_SERVICE: '/new-extra-service',
  INTERNAL_SERVICES: '/configure-hotels/:hotelId/internal-services',
  INTERNAL_SERVICES_DETAIL:
    '/configure-hotels/:hotelId/internal-services/:serviceId',
  EDIT_INTERNAL_SERVICE:
    '/configure-hotels/:hotelId/internal-services/:serviceId/edit',
  EXTERNAL_SERVICES_HOTEL: '/configure-hotels/:hotelId/external-services',
  CREATE_INTERNAL_SERVICE: '/configure-hotels/:hotelId/new-internal-service',
  HOTEL_EXTERNAL_SERVICES: '/configure-hotels/:hotelId/external-services',
  EXTERNAL_SERVICES: '/external-services',
  HOTEL_CONFIG_LIST: '/configure-hotels',
  LOYALTY_SETTINGS: '/loyalty-settings',
};

export default ROUTES;
