import { action } from 'typesafe-actions';
import ActionTypes from './constants';

export const toggleDashboardCollapsed = () => {
  return action(ActionTypes.DASHBOARD_TOGGLE_COLLAPSED);
};

export const switchCurrentCityId = (cityID: string) =>
  action(ActionTypes.SWITCH_CURRENT_CITY, cityID);

export const switchCurrentCitySuccess = (cityID: string) =>
  action(ActionTypes.SWITCH_CURRENT_CITY_SUCCESS, cityID);
