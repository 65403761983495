import React from 'react';
import { DatePicker } from 'antd';
import { Icon } from '../Icon';
import { createGlobalStyle } from 'styled-components';
import { RangePickerProps } from 'antd/lib/date-picker/interface';

const { RangePicker } = DatePicker;

const GlobalStyles = createGlobalStyle`
  .ant-calendar-range-middle {
    padding: 0 !important;
  }
  .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: 0 !important;
  }
  .ant-calendar-input {
    text-align: center !important;
  }
  .ant-calendar-body {
    border-top: unset !important;
  }
  .ant-calendar-range .ant-calendar-in-range-cell:before, .ant-calendar-date:hover {
    background: var(--green-lighter) !important;
  }
  .ant-calendar-selected-start-date
  .ant-calendar-date:hover,
  .ant-calendar-selected-end-date
  .ant-calendar-date:hover {
    background: var(--primary) !important;
  }
  .ant-calendar-date {
    line-height: 25px !important;
    transition: none !important;
  }
  .ant-calendar-selected-start-date .ant-calendar-date {
    border-radius: 2px 0px 0px 2px !important;
  }
  .ant-calendar-selected-end-date .ant-calendar-date {
    border-radius: 0px 2px 2px 0px !important;
  }
  .ant-calendar-range-left .ant-calendar-input-wrap {
    margin-left: 12px!important;
  }
  .ant-calendar-range-right .ant-calendar-input-wrap {
    margin-right: 12px!important;
  }
  .ant-calendar-header {
    padding-right: 12px!important;
    padding-left: 8px!important;
  }
  .ant-calendar-month-panel-body {
    border-top: unset !important;
  }
  .ant-calendar-month-panel-header, .ant-calendar-header{
    margin-top: 10px;
  }
  .ant-calendar-month-panel-prev-year-btn {
    margin-top: 10px;
    left: 16px !important;
  }
  .ant-calendar-month-panel-next-year-btn {
    margin-top: 10px;
    right: 16px !important;
  }
  .ant-calendar-today .ant-calendar-date{
    font-weight: 500 !important;
  }
  .ant-calendar-range
    .ant-calendar-in-range-cell:before {
    top: 4px !important;
    bottom: 5px !important;
  }

  .ant-calendar-cell{
    height: 34px !important;
  }
  .ant-calendar-month-panel-table {
    height: 100% !important;
  }
  .ant-calendar-cell.ant-calendar-today.ant-calendar-selected-date .ant-calendar-date {
    background: unset !important;
    color: var(--primary) !important;
    border-color: var(--primary) !important;
  }

  .ant-calendar-today.ant-calendar-selected-start-date.ant-calendar-selected-date.ant-calendar-selected-day .ant-calendar-date{
    background: var(--primary) !important;
    color: var(--white) !important;
  }
`;

export interface DateRangePickerProps extends RangePickerProps {}

export function DateRangePicker(props: DateRangePickerProps) {
  return (
    <>
      <GlobalStyles />
      <RangePicker
        {...props}
        suffixIcon={<Icon className="text-xs text-black" type="calendar-alt" />}
      />
    </>
  );
}

export default DateRangePicker;
