import { call, put, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import { fetchUnitsSuccess, fetchUnitsFailure } from './actions';
import ActionTypes from './constants';

export function* fetchUnits() {
  try {
    const { data } = yield call(coreAPI.get, 'v1/operation/units');
    yield put(fetchUnitsSuccess(data || []));
  } catch (err) {
    yield put(fetchUnitsFailure(err));
  }
}

export default function* watchFetchUnits() {
  yield takeLatest(ActionTypes.FETCH_UNITS, fetchUnits);
}
