import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectLocationsState = (state: ApplicationRootState) => {
  return state.locations || initialState;
};

const makeSelectLocationsLoading = () =>
  createSelector(
    selectLocationsState,
    locationsState => locationsState.loading,
  );

const makeSelectLocationsError = () =>
  createSelector(selectLocationsState, locationsState => locationsState.error);

const makeSelectLocations = () =>
  createSelector(
    selectLocationsState,
    locationsState => locationsState.locations,
  );

export {
  makeSelectLocations,
  makeSelectLocationsError,
  makeSelectLocationsLoading,
};
