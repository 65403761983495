import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { HotelGetHotelConfigurationResponse } from 'types/schema';

export interface ViewmodelDistrict {
  cityID?: string;
  id?: string;
  name?: string;
  refCode?: string;
}
export interface DistrictListResponse {
  data?: ViewmodelDistrict[];
}

export const fetchHotelConfigList = (payload: {
  cityId: String;
  filterId: String;
}) => {
  return action(ActionTypes.FETCH_HOTEL_CONFIG_LIST, payload);
};

export const fetchHotelConfigListSuccess = (
  data: HotelGetHotelConfigurationResponse,
) => {
  return action(ActionTypes.FETCH_HOTEL_CONFIG_LIST_SUCCESS, data);
};

export const fetchHotelConfigListFailure = (error: Error) =>
  action(ActionTypes.FETCH_HOTEL_CONFIG_LIST_FAILURE, error);

export const fetchDistrictbyCityID = (payload: { cityId: String }) => {
  return action(ActionTypes.FETCH_DISTRICT, payload);
};

export const fetchDistrictbyCityIDSuccess = (data: ViewmodelDistrict[]) => {
  return action(ActionTypes.FETCH_DISTRICT_SUCCESS, data);
};

export const fetchDistrictbyCityIDFailure = (error: Error) =>
  action(ActionTypes.FETCH_DISTRICT_FAILURE, error);
