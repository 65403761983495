import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ServiceUnit } from 'types/schema';

export const fetchUnits = () => action(ActionTypes.FETCH_UNITS);

export const fetchUnitsSuccess = (units: ServiceUnit[]) =>
  action(ActionTypes.FETCH_UNITS_SUCCESS, units);

export const fetchUnitsFailure = (error: Error) =>
  action(ActionTypes.FETCH_UNITS_FAILURE, error);
