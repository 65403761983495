import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectExternalServiceState = (state: ApplicationRootState) => {
  return state.externalService || initialState;
};

const makeSelectIsLoading = () =>
  createSelector(
    selectExternalServiceState,
    externalServiceState => externalServiceState.isLoading,
  );

const makeSelectError = () =>
  createSelector(
    selectExternalServiceState,
    externalServiceState => externalServiceState.error,
  );

const makeSelectExternalService = () =>
  createSelector(
    selectExternalServiceState,
    externalServiceState => externalServiceState.externalServices,
  );

const makeSelectExternalServiceDetail = () =>
  createSelector(
    selectExternalServiceState,
    externalServiceState => externalServiceState.serviceDetail,
  );

const makeSelectExternalServiceDetailLoading = () =>
  createSelector(
    selectExternalServiceState,
    externalServiceState => externalServiceState.isServiceDetailLoading,
  );
const makeSelectHotelList = () =>
  createSelector(
    selectExternalServiceState,
    externalServiceState => externalServiceState.hotelList,
  );

export {
  makeSelectError,
  makeSelectExternalService,
  makeSelectIsLoading,
  makeSelectExternalServiceDetail,
  makeSelectExternalServiceDetailLoading,
  makeSelectHotelList,
};
