import { CategoriesState, CategoriesAction } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: CategoriesState = {
  loading: false,
  error: null,
  categories: null,
  hotelCategories: null,
  hotelCategoriesLoading: false,
};

const categoriesReducer = (
  state: CategoriesState = initialState,
  action: CategoriesAction,
): CategoriesState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_CATEGORIES:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_CATEGORIES_SUCCESS:
        draft.loading = false;
        draft.categories = action.payload;
        break;
      case ActionTypes.FETCH_CATEGORIES_FAILURE:
        draft.loading = false;
        break;

      case ActionTypes.FETCH_HOTEL_CATEGORIES:
        draft.hotelCategoriesLoading = true;
        break;
      case ActionTypes.FETCH_HOTEL_CATEGORIES_SUCCESS:
        draft.hotelCategoriesLoading = false;
        draft.hotelCategories = action.payload;
        break;
      case ActionTypes.FETCH_HOTEL_CATEGORIES_FAILURE:
        draft.hotelCategoriesLoading = false;
        break;
    }
  });

export default categoriesReducer;
