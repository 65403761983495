import React from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import { Upload } from 'antd';
import { showErrorNotification } from '@aha/utils';
import { Icon } from '@aha/ui';
import { ReactComponent as Indicator } from './Indicator.svg';

const Container = styled.div<{ uploading: number; required: boolean }>`
  ${tw`rounded-sm border flex flex-col items-center justify-center flex-none`}
  width: 213px;
  height: 107px;
  border-color: ${props =>
    props.required
      ? 'var(--red)'
      : props.uploading
      ? 'var(--grey-darker)'
      : 'var(--black-lightest)'};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  animation: ${rotate} 2s linear infinite;
`;

const IconWrapper = styled.div`
  ${tw`flex-none inline-flex items-center justify-center`}
  width: 50px;
  height: 50px;
`;

export interface MultipleImagesUploadItemProps {
  onChange?: (file: any) => void;
  required?: boolean;
  uploading?: boolean;
  addPhotoText?: string;
  uploadingText?: string;
}

const MultipleImagesUploadItem = ({
  onChange,
  uploading = false,
  required = false,
  addPhotoText = 'Add Photo',
  uploadingText = 'Photo is being uploaded',
}: MultipleImagesUploadItemProps) => {
  return (
    <Upload
      accept="image/*"
      disabled={uploading}
      showUploadList={false}
      //@ts-ignore
      customRequest={({ file, onSuccess }) => {
        if (file.size > 2000000) {
          const err = new Error('Image size is not greater than 2 MB');
          showErrorNotification('Can not upload image', err);
          return false;
        }
        setTimeout(() => {
          onSuccess('ok');
        }, 0);
      }}
      onChange={({ file }) => {
        if (file.status !== 'uploading') {
          onChange && onChange(file.originFileObj);
        }
      }}
    >
      <Container uploading={uploading ? 1 : 0} required={required}>
        {uploading ? (
          <Rotate>
            <Indicator className="text-blue" />
          </Rotate>
        ) : (
          <IconWrapper>
            <Icon type="plus" className="text-2xl" />
          </IconWrapper>
        )}
        {uploading ? (
          <span className="font-medium text-grey-darker mt-2">
            {uploadingText}
          </span>
        ) : (
          <span className="font-medium mt-2">{addPhotoText}</span>
        )}
      </Container>
    </Upload>
  );
};

export default MultipleImagesUploadItem;
