import React from 'react';
import { Table, Modal, FormInputNumber, Icon } from '@aha/ui';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Wrapper, Cell, usCoinsCols, earnedCoinsCols, Schema } from '..';
import { ModelCoin } from 'types/schema';
import { Tooltip } from 'antd';
import { numberFormatterBack } from '@aha/utils';

const Container = styled.form`
  .ant-form-item {
    ${tw`w-full`}
    margin-bottom: 0px !important;
  }

  input {
    ${tw`w-full`}
  }
`;

export interface EditSettingModalProps {
  visible?: boolean;
  onSubmit: (value: ModelCoin) => void;
  onCancel?: () => void;
  data: ModelCoin;
  submitting?: boolean;
}

export interface InnerModalProps {
  submitForm: (value: ModelCoin) => void;
  formInitialValues: ModelCoin;
}

export const InnerModal = ({
  submitForm,
  formInitialValues,
}: InnerModalProps) => {
  const earnedData: Schema[] = [
    {
      key: 'earned_coins_1',
      title: 'Percent per booking',
      daily: (
        <FormInputNumber
          className="w-full"
          name="earningRate"
          min={0}
          max={100}
          placeholder="10%"
        />
      ),
      hourly: (
        <FormInputNumber
          className="w-full"
          name="hourlyBookingEarningRate"
          min={0}
          max={100}
          placeholder="8%"
        />
      ),
    },
    {
      key: 'earned_coins_2',
      title: 'Max coins burn for a room',
      daily: (
        <FormInputNumber
          className="w-full"
          name="maxEarning"
          formatter={(value: number | string | undefined = 0) => {
            const formatNumber = value
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

            return formatNumber;
          }}
          parser={(value?: string) =>
            numberFormatterBack((value || '0') as string)
          }
          min={0}
          placeholder="200 coins"
        />
      ),
      hourly: (
        <FormInputNumber
          className="w-full"
          name="hourlyBookingMaxEarning"
          formatter={(value: number | string | undefined = 0) => {
            const formatNumber = value
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

            return formatNumber;
          }}
          parser={(value?: string) =>
            numberFormatterBack((value || '0') as string)
          }
          min={0}
          placeholder="50 coins"
        />
      ),
    },
  ];

  const usData: Schema[] = [
    {
      key: 'using_coins',
      title: (
        <div className="inline-flex items-center">
          Max coins burn for a room
          <Tooltip
            title="How many coins to discount when paying?"
            arrowPointAtCenter
          >
            <Icon
              type="note-circle"
              className="ml-2 text-base text-grey-darker"
            />
          </Tooltip>
        </div>
      ),
      daily: (
        <FormInputNumber
          className="w-full"
          name="maxExpense"
          min={0}
          formatter={(value: number | string | undefined = 0) => {
            const formatNumber = value
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

            return formatNumber;
          }}
          parser={(value?: string) =>
            numberFormatterBack((value || '0') as string)
          }
          placeholder="10%"
        />
      ),
      hourly: (
        <FormInputNumber
          className="w-full"
          name="hourlyBookingMaxExpense"
          min={0}
          formatter={(value: number | string | undefined = 0) => {
            const formatNumber = value
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

            return formatNumber;
          }}
          parser={(value?: string) =>
            numberFormatterBack((value || '0') as string)
          }
          placeholder="8%"
        />
      ),
    },
  ];

  return (
    <Form
      onSubmit={submitForm}
      initialValues={formInitialValues}
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit }) => (
        <Container
          onSubmit={handleSubmit}
          id="edit-setting-loyalty-form"
          data-testid="edit-setting-loyalty-form"
        >
          <section className="pb-6">
            <Wrapper className="border rounded-sm">
              <Cell className="w-2/5 border-r font-medium">
                1 coins used to discount when making payment equivalent to how
                much money?
              </Cell>
              <Cell className="w-3/5 justify-center">
                <FormInputNumber
                  className="w-full"
                  name="exchangeRate"
                  min={0}
                  formatter={(value: number | string | undefined = 0) => {
                    const formatNumber = value
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

                    return formatNumber;
                  }}
                  parser={(value?: string) =>
                    numberFormatterBack((value || '0') as string)
                  }
                  placeholder="đ1,000"
                />
              </Cell>
            </Wrapper>
          </section>
          <section className="pb-6">
            <Table
              bordered
              borderBottom
              columns={usCoinsCols}
              dataSource={usData}
              pagination={false}
            />
          </section>
          <section className="pb-6">
            <Table
              bordered
              borderBottom
              columns={earnedCoinsCols}
              dataSource={earnedData}
              pagination={false}
            />
          </section>
          <section className="pb-6">
            <Wrapper className="border rounded-sm">
              <Cell className="w-2/5 border-r font-medium">
                How long the number of Coins that customers earned is expired?
              </Cell>
              <Cell className="w-3/5 justify-center">
                <FormInputNumber
                  className="w-full"
                  name="validTime"
                  min={0}
                  placeholder="180 Days"
                />
              </Cell>
            </Wrapper>
          </section>
          <section className="pb-6">
            <Wrapper className="border rounded-sm">
              <Cell className="w-2/5 border-r font-medium">
                Valid time for new coins earned to be used since the booking was
                checked out
              </Cell>
              <Cell className="w-3/5 justify-center">
                <FormInputNumber
                  className="w-full"
                  name="useableAfter"
                  min={0}
                  placeholder="48 hours"
                />
              </Cell>
            </Wrapper>
          </section>
          <section className="pb-6">
            <Wrapper className="border rounded-sm">
              <Cell className="w-2/5 border-r font-medium">
                The maximum amount the hotel will pay for a room when using
                coins for payment
              </Cell>
              <Cell className="w-3/5 justify-center">
                <FormInputNumber
                  className="w-full"
                  name="hotelPayableRate"
                  min={0}
                  formatter={(value: number | string | undefined = 0) => {
                    const formatNumber = value
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

                    return formatNumber;
                  }}
                  parser={(value?: string) =>
                    numberFormatterBack((value || '0') as string)
                  }
                  placeholder="đ200,000"
                />
              </Cell>
            </Wrapper>
          </section>
        </Container>
      )}
    />
  );
};

const EditSettingModal = ({
  visible = false,
  onSubmit,
  onCancel,
  data,
  submitting = false,
}: EditSettingModalProps) => {
  const { earningRate = 0, hourlyBookingEarningRate = 0, ...initData } = data;
  const formInitialValues = {
    ...initData,
    earningRate: earningRate * 100,
    hourlyBookingEarningRate: hourlyBookingEarningRate * 100,
  };

  return (
    <Modal
      title="Edit customize loyalty"
      visible={visible}
      width={948}
      submitText="Save"
      cancelText="Cancel"
      onSubmit={() => {
        document
          ?.getElementById('edit-setting-loyalty-form')
          ?.dispatchEvent(new Event('submit', { cancelable: true }));
      }}
      onCancel={() => onCancel && onCancel()}
      submitting={submitting}
    >
      <InnerModal submitForm={onSubmit} formInitialValues={formInitialValues} />
    </Modal>
  );
};

export default EditSettingModal;
